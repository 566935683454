import { Button, Checkbox, Fade, FormControl, FormControlLabel, Input, InputLabel } from '@mui/material'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { toastError } from '../../../../shared/scripts/utilities'
import { ProgramsContext } from '../Context'

export default function CreateProgram() {
    const ctx = useContext(ProgramsContext)

    const [form, setForm] = useState({
        name: '',
        temp_logger: false
    })

    const _handleSubmit = async () => {
        try {
            const resp = await ctx.axios.post('/api/programs', form)
            toast.success(resp.data.message)
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <>
            <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                Novo Programa
            </h3>
            <Fade in={true}>

                <div className='w-full flex flex-col jusitfy-center items-center'>
                    <form
                        id='crt_pgm_frm'
                        onSubmit={e => {
                            e.preventDefault()
                            _handleSubmit()
                        }}

                        className='w-full max-w-lg grid grid-cols-1 gap-4 py-2'
                    >
                        <FormControl>
                            <InputLabel htmlFor='program'>Nome do Programa</InputLabel>
                            <Input
                                id='program'
                                aria-describedby='nome do programa'
                                value={form.username}
                                onChange={e => setForm({ ...form, name: e.target.value })}
                            />
                        </FormControl>

                        <FormControlLabel
                            label="Acompanhamento de temperatura"
                            control={
                                <Checkbox
                                    checked={form.temp_logger}
                                    onChange={e => setForm({ ...form, temp_logger: e.target.checked })}
                                />
                            }
                        />

                        <div className='w-full flex justify-between items-center'>
                            <Button
                                type='reset'
                                onClick={() => setForm({ name: '' })}
                            >Limpar</Button>

                            <Button
                                type='submit'
                                variant='contained'
                            >Criar</Button>
                        </div>

                    </form>
                </div>
            </Fade>
        </>
    )
}
