import { Delete, Edit } from '@mui/icons-material'
import { CircularProgress, FormControl, Input, InputLabel } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { dataTable } from '../../../../context/DataTableConfig'
import { formatString, toastError } from '../../../../shared/scripts/utilities'
import ModalFrame from '../../../../shared/components/ModalFrame'
import Confirm from '../../../../shared/components/Confirm'
import EditAgenda from '../forms/EditAgenda'
import { AgendasContext } from '../Context'
import { AppContext } from '../../../../context/AppContextProvider'

export default function AgendasTable() {
    const appCtx = useContext(AppContext)
    const ctx = useContext(AgendasContext)
    const [search, setSearch] = useState('')
    const [agenda, setAgenda] = useState(null)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)

    const readOnly = !appCtx.hasAbility('pautas:write');

    const columns = [
        {
            name: 'ID',
            cell: row => row.id,
            selector: row => JSON.stringify(row.id),
            sortable: true,
            center: true,
        },
        {
            name: 'Pauta',
            cell: row => row.name,
            selector: row => JSON.stringify(row.name),
            sortable: true,
            center: true,
        },
        {
            name: 'Ações',
            cell: row => <div className={`w-full flex justify-center`}>
                <button
                    title='editar'
                    className={`cursor-pointer ${readOnly ? 'text-gray-500 pointer-events-none': 'text-gray-800'}`}
                    disabled={readOnly}
                    onClick={() => _openEdit(row)}>
                    <Edit />
                </button>
                <button
                    title='excluir'
                    className={`cursor-pointer ${readOnly ? 'text-gray-500 pointer-events-none': 'text-gray-800'}`}
                    disabled={readOnly}
                    onClick={() => _confirmDelete(row)}>
                    <Delete />
                </button>
            </div>,
            center: true,
        }
    ]

    const _handleDelete = async id => {
        try {
            const resp = await ctx.axios.delete(`/api/agendas/${id}`)
            toast.success(resp.data.message)
            setAgenda(null)
            ctx.update()
        } catch (err) {
            toastError(err)
            setAgenda(null)
        }
    }

    const _openEdit = row => {
        setAgenda(row)
        setEditOpen(true)
    }

    const _confirmDelete = row => {
        setAgenda(row)
        setDeleteOpen(true)
    }

    useEffect(() => {
        ctx.update()
        return () => {
            const ac = new AbortController();
            ac.abort();
        }
    }, [])

    return (
        <>
            <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                Gerenciar Pautas
            </h3>
            <DataTable
                data={ctx.agendas.filter(item => {
                    if (
                        formatString(item.id).indexOf(formatString(search)) !== -1 ||
                        formatString(item.name).indexOf(formatString(search)) !== -1
                    )
                        return true
                    else
                        return false
                })}
                subHeader
                subHeaderAlign={window.screen.width > 400 ? 'right' : 'left'}
                subHeaderComponent={<FormControl>
                    <InputLabel htmlFor='srch_agd'>Buscar</InputLabel>
                    <Input
                        id='srch_agd'
                        value={search}
                        onChange={e => setSearch(e.target.value)} />
                </FormControl>}
                columns={columns}
                pagination
                paginationComponentOptions={dataTable.options.paginationConponentOptions}
                customStyles={dataTable.theme.default}
                progressPending={ctx.pending}
                progressComponent={<CircularProgress />}
                noDataComponent={'Nenhum resultado'}
                sortIcon={dataTable.options.sortIcon}
                sortFunction={dataTable.options.sortFunction}
            />
            {(agenda && (<ModalFrame
                open={editOpen}
                onClose={() => {
                    setAgenda(null)
                    setEditOpen(false)
                }}>
                <EditAgenda agendaId={agenda.id} />
            </ModalFrame>))}

            {(agenda && (<Confirm
                open={deleteOpen}
                setOpen={setDeleteOpen}
                title='Excluir Pauta'
                message={`Confirmar exclusão da pauta ${agenda.name}?`}
                onConfirm={() => _handleDelete(agenda.id)}
            />))}

        </>
    )
}
