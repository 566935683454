import React, { useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Agendas from '../pages/agenda'
import CreateAgenda from '../pages/agenda/components/forms/CreateAgenda'
import AgendasTable from '../pages/agenda/components/tables/Agendas'
import Classifications from '../pages/classifications'
import CreateClassification from '../pages/classifications/components/forms/CreateClassification'
import ClassificationsTable from '../pages/classifications/components/tables/Classifications'
import Corporations from '../pages/corporations'
import CreateCorporation from '../pages/corporations/components/forms/CreateCorporation'
import CorporationsTable from '../pages/corporations/components/tables/Corporations'
import Home from '../pages/home'
import LoginPage from '../pages/login/LoginPage'
import Mails from '../pages/mails'
import CreateMail from '../pages/mails/components/forms/CreateMail'
import MailsTable from '../pages/mails/components/tables/Mails'
import Orders from '../pages/orders'
import CreateOrder from '../pages/orders/components/CreateOrder'
import OrdersTable from '../pages/orders/tables/Orders'
import Programs from '../pages/programs'
import CreateProgram from '../pages/programs/components/forms/CreateProgram'
import ProgramsTable from '../pages/programs/components/tables/Programs'
import Settings from '../pages/settings'
import Users from '../pages/users'
import CreateUser from '../pages/users/components/forms/CreateUser'
import UsersTable from '../pages/users/components/tables/Users'
import { AppContext } from './AppContextProvider'

export default function AppRoutes() {
    const appCtx = useContext(AppContext)

    return (
        <Routes>
            <Route path='/' element={<Home />} />

        
            <Route path='login' element={<LoginPage />} />

            {
                appCtx.hasAbility('pedidos:')
                    ? <Route path='pedidos' element={<Orders />}>
                        <Route key='Pedidos' index element={<OrdersTable />} />
                        {
                            appCtx.hasAbility('pedidos:write')
                                ? <Route key='Novo' path='novo' element={<CreateOrder />} />
                                : null
                        }
                    </Route>
                    : null
            }

            {
                appCtx.hasAbility('classificacoes:')
                    ? <Route path='classificacoes' element={<Classifications />}>
                        <Route key='Gerenciar' index element={<ClassificationsTable />} />
                        {
                            appCtx.hasAbility('classificacoes:write')
                                ? <Route key='Novo' path='novo' element={<CreateClassification />} />
                                : null
                        }
                    </Route>
                    : null
            }

            {
                appCtx.hasAbility('pautas:')
                    ? <Route path='pautas' element={<Agendas />}>
                        <Route key='Gerenciar' index element={<AgendasTable />} />
                        {
                            appCtx.hasAbility('pautas:write')
                                ? <Route key='Novo' path='novo' element={<CreateAgenda />} />
                                : null
                        }
                    </Route>
                    : null
            }

            {
                appCtx.hasAbility('programas:')
                    ? <Route path='programas' element={<Programs />}>
                        <Route key='Gerenciar' index element={<ProgramsTable />} />
                        {
                            appCtx.hasAbility('programas:write')
                                ? <Route key='Novo' path='novo' element={<CreateProgram />} />
                                : null
                        }
                    </Route>
                    : null
            }

            {
                appCtx.hasAbility('admin')
                    ? <Route path='configuracoes'>
                        <Route key='idx' index element={<Settings />} />
                        <Route key='Usuários' path='usuarios' element={<Users />}>
                            <Route key='Gerenciar' index element={<UsersTable />} />
                            <Route key='Novo' path='novo' element={<CreateUser />} />
                        </Route>
                        <Route key='Empresas' path='empresas' element={<Corporations />}>
                            <Route key='Gerenciar' index element={<CorporationsTable />} />
                            <Route key='Novo' path='novo' element={<CreateCorporation />} />
                        </Route>
                        <Route key='Emails' path='emails' element={<Mails />}>
                            <Route key='Gerenciar' index element={<MailsTable />} />
                            <Route key='Novo' path='novo' element={<CreateMail />} />
                        </Route>
                    </Route>
                    : null
            }

            <Route path='*' element={<Navigate to={'/'} />} />

        </Routes>
    )
}
