import { ThemeProvider } from '@mui/system'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppContextProvider from '../context/AppContextProvider'
import { button } from '../context/MUIThemes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Index from './Index'

export default function App() {
    return (
        <>
            <BrowserRouter>
                <AppContextProvider>
                    <ThemeProvider theme={button}>
                        <Index />
                        <ToastContainer
                            limit={3}
                        />
                    </ThemeProvider>
                </AppContextProvider>
            </BrowserRouter>
        </>
    )
}

// export default AuthComponent(App)