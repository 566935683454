import { Breadcrumbs } from '@mui/material'
import _ from 'lodash';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../../../styles/Breadcrumbs.css';
import { routes } from '../../config/routes';


export default function PageTitle() {

    const navigate = useNavigate()
    let steps = window.location.href.replace(window.location.origin + '/', '').split('/')

    return (
        <div className='w-full py-4 pl-8 text-white capitalize text-2xl  ms-blue'>
            <Breadcrumbs
                separator='/'
            >
                {

                    _.isEmpty(steps[0])
                        ? <span
                            key={'home'}
                            className='text-white capitalize text-2xl cursor-pointer'
                            onClick={e => navigate('/')}
                        >Início</span>
                        : steps.map(route => {
                            let routeDef = routes.find(r => r.path === route)
                            return (<span
                                key={route}
                                className='text-white capitalize text-2xl cursor-pointer'
                                onClick={e => {
                                    if (steps.indexOf(route) === (steps.length - 1)) return;
                                    navigate(route !== steps[0] ? `/${steps[0]}/${route}` : `/${route}`)
                                }}
                            >{routeDef ? routeDef.name : route}</span>)
                        })
                }

            </Breadcrumbs>
        </div>
    )
}