import { Button, FormControl, Input, InputLabel, LinearProgress } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { toastError } from '../../../../shared/scripts/utilities'
import { AgendasContext } from '../Context'

export default function EditAgenda({ agendaId }) {

    const ctx = useContext(AgendasContext)

    const [form, setForm] = useState({})

    const _handleSubmit = async () => {
        try {
            const resp = await ctx.axios.put(`/api/agendas/${agendaId}`, form)
            toast.success(resp.data.message)
            ctx.update()
        } catch (err) {
            toastError(err)
        }
    }

    const _fetchAgenda = async () => {
        try {
            const resp = await ctx.axios.get(`/api/agendas/${agendaId}`)
            setForm(resp.data)

        } catch (err) {
            toastError(err)
        }
    }

    useEffect(() => {
        _fetchAgenda()
        return () => {
            const ac = new AbortController()
            ac.abort()
        }
    }, [])

    return (
        <>
            {
                ctx.pending || _.isEmpty(form)
                    ? <LinearProgress />
                    : <>
                        <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                            Nova Pauta
                        </h3>
                        <div className='w-full flex flex-col jusitfy-center items-center'>
                            <form
                                id='edt_agd_frm'
                                onSubmit={e => {
                                    e.preventDefault()
                                    _handleSubmit()
                                }}

                                className='w-full max-w-lg grid grid-cols-1 gap-4 py-2'
                            >
                                <FormControl>
                                    <InputLabel htmlFor='agenda'>Nome da Pauta</InputLabel>
                                    <Input
                                        id='agenda'
                                        aria-describedby='nome da pauta'
                                        value={form.name}
                                        onChange={e => setForm({ ...form, name: e.target.value })}
                                    />
                                </FormControl>

                                <div className='w-full flex justify-end items-center'>

                                    <Button
                                        type='submit'
                                        variant='contained'
                                    >Salvar</Button>
                                </div>

                            </form>
                        </div>
                    </>

            }
        </>

    )
}
