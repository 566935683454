import React, { createContext, useContext, useState } from 'react'
import { AppContext } from '../../../context/AppContextProvider'
import { toastError } from '../../../shared/scripts/utilities'

export const ClassificationsContext = createContext(null)
export function ClassificationsContextProvider({ children }) {
    const appCtx = useContext(AppContext)
    const [classifications, setClassifications] = useState([])
    const [pending, setPending] = useState(false)

    const _fetchClassifications = async () => {
        try {
            setPending(true)
            const resp = await appCtx.axios.get('/api/classifications')
            setClassifications(resp.data)
            setPending(false)
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <ClassificationsContext.Provider
            value={{
                classifications,
                setClassifications,
                pending,
                axios: appCtx.axios,
                update: _fetchClassifications,
            }}
        >
            {children}
        </ClassificationsContext.Provider>
    )
}
