import _ from 'lodash'
import React, { useContext } from 'react'
import { AppContext } from '../context/AppContextProvider'
import AppRoutes from '../context/AppRoutes'
import ChangePwPage from '../pages/login_change_pw/ChangePwPage'
import Navigation from './Navigation'

export default function Index() {
    const appCtx = useContext(AppContext)
    return (
        <>
            {
                !appCtx.loadingApp
                    ? appCtx.userInfo.needs_password_reset
                        ? <ChangePwPage />
                        : <div className='grid'>
                            {
                                 !_.isEmpty(appCtx.userInfo) && appCtx.loggedIn && (window.location.href.indexOf('/login') === -1)
                                    ? <Navigation />
                                    : null

                            }
                            <main className='main w-full'>
                                {/* <Routes>
                                    <Route path='/' element={<Home />} />
                                    {
                                        appCtx.allowed_routes.map(route => {
                                            if (route.name === 'home') return;

                                            return (
                                                <Route
                                                    key={route.name}
                                                    path={route.path}
                                                    element={route.element}
                                                    exact
                                                >
                                                    {(route.children)}
                                                </Route>
                                            )
                                        })
                                    }
                                    <Route path='login' element={<LoginPage />} />
                                </Routes> */}
                                <AppRoutes />
                            </main>
                        </div>
                    : null
            }
        </>
    )
}
