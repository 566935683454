import { Button, Checkbox, FormControl, FormControlLabel, Input, InputLabel, LinearProgress } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { toastError } from '../../../../shared/scripts/utilities'
import { MailsContext } from '../Context'

export default function EditMail({ mailId }) {

    const ctx = useContext(MailsContext)

    const [form, setForm] = useState({
        email: '',
        active: false
    })

    const _handleSubmit = async () => {
        try {
            const resp = await ctx.axios.put(`/api/settings/mailing/${mailId}`, {
                user_data: {
                    ...form.data,
                    username: form.username,
                    email: form.email
                }
            })
            toast.success(resp.data.message)
            ctx.update()
        } catch (err) {
            toastError(err)
        }
    }

    const _fetchMail = async () => {
        try {
            const resp = await ctx.axios.get(`/api/settings/mailing/${mailId}`)
            setForm(resp.data)

        } catch (err) {
            toastError(err)
        }
    }

    useEffect(() => {
        _fetchMail()
        return () => {
            const ac = new AbortController()
            ac.abort()
        }
    }, [])

    return (
        <>
            {
                ctx.pending || _.isEmpty(form)
                    ? <LinearProgress />
                    : <>
                        <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                            Editar Email
                        </h3>
                        <div className='w-full flex flex-col jusitfy-center items-center'>
                            <form
                                id='edt_usr_frm'
                                onSubmit={e => {
                                    e.preventDefault()
                                    _handleSubmit()
                                }}

                                className='w-full max-w-lg grid grid-cols-1 gap-4 py-2'
                            >

                                <FormControl>
                                    <InputLabel htmlFor='email'>E-mail*</InputLabel>
                                    <Input
                                        id='email'
                                        aria-describedby='email de usuário'
                                        value={form.email}
                                        onChange={e => setForm({ ...form, email: e.target.value })}
                                    />
                                </FormControl>

                                <FormControlLabel
                                    label="Ativo"
                                    control={
                                        <Checkbox
                                            checked={form.active}
                                            onChange={e => setForm({ ...form, active: e.target.checked })}
                                        />
                                    }
                                />

                                <div className='w-full flex justify-end items-center'>

                                    <Button
                                        type='submit'
                                        variant='contained'
                                    >Salvar</Button>
                                </div>

                            </form>
                        </div>
                    </>

            }
        </>

    )
}
