import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

export default function Confirm({
    open,
    setOpen,
    title,
    message,
    cancelTxt,
    confirmTxt,
    onConfirm,
    onCancel
}) {

    const _close = () => setOpen(false)
    const _confirm = () => {
        if (typeof onConfirm == 'function') onConfirm();
        _close();
    }
    const _cancel = () => {
        if (typeof onCancel == 'function') onCancel();
        _close();
    }

    return (
        <Dialog
            open={open}
            onClose={_close}
        >
            <DialogTitle>{title ?? 'Confirmação'}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message ?? 'Deseja prosseguir com a ação?'}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={_cancel}
                >{cancelTxt ?? 'Cancelar'}</Button>
                <Button
                    onClick={_confirm}
                >{confirmTxt ?? 'Confirmar'}</Button>
            </DialogActions>
        </Dialog>
    )
}
