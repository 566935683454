import { Dialog, DialogContent } from '@mui/material'
import React from 'react'

export default function ModalFrame({ children, open, onClose }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
        >
            <DialogContent>
                <div className='w-full h-full flex items-center justify-center'>
                    <div className='bg-white rounded-md flex-1'>
                        {children}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
