import React, { createContext, useContext, useState } from 'react'
import { AppContext } from '../../../context/AppContextProvider'
import { toastError } from '../../../shared/scripts/utilities'

export const ProgramsContext = createContext(null)
export function ProgramsContextProvider({ children }) {
    const appCtx = useContext(AppContext)
    const [programs, setPrograms] = useState([])
    const [pending, setPending] = useState(false)

    const _fetchPrograms = async () => {
        try {
            setPending(true)
            const resp = await appCtx.axios.get('/api/programs')
            setPrograms(resp.data)
            setPending(false)
        } catch (err) {
            toastError(err)
        }
    }
    return (
        <ProgramsContext.Provider
            value={{
                programs,
                setPrograms,
                pending,
                axios: appCtx.axios,
                update: _fetchPrograms,
            }}
        >
            {children}
        </ProgramsContext.Provider>
    )
}
