import { Button, FormControl, Input, InputLabel, LinearProgress } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AppContext } from '../../../../context/AppContextProvider'
import { toastError } from '../../../../shared/scripts/utilities'
import { UsersContext } from '../Context'
import UserPermissions from './UserPermissions'

export default function EditUser({ userId }) {

    const appCtx = useContext(AppContext)
    const ctx = useContext(UsersContext)

    const [form, setForm] = useState({})
    const [back, setBack] = useState(false)

    // handlers
    const _handleSubmit = async () => {
        try {
            const resp = await appCtx.axios.put(`/api/users/${userId}`, {
                user_data: {
                    ...form.data,
                    username: form.username,
                    email: form.email
                }
            })
            toast.success(resp.data.message)
            ctx.update()
        } catch (err) {
            toastError(err)
        }
    }

    // fetchs
    const _fetchUser = async () => {
        try {
            const resp = await appCtx.axios.get(`/api/users/${userId}`)
            setForm(resp.data)
            // _parseValues(resp.data.permissions.abilities);

        } catch (err) {
            toastError(err)
        }
    }

    useEffect(() => {
        _fetchUser();
        return () => {
            const ac = new AbortController()
            ac.abort()
        }
    }, [])

    return (
        <>
            {
                ctx.pending || _.isEmpty(form)
                    ? <LinearProgress />
                    : <>
                        <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                            Editar Usuário
                        </h3>
                        <div className='w-full grid grid-cols-2 border-b mb-4'>
                            <Button
                                color='neutral'
                                onClick={() => setBack(false)}
                            >
                                Perfil
                            </Button>
                            <Button
                                color='neutral'
                                onClick={() => setBack(true)}
                            >
                                Permissões
                            </Button>
                        </div>
                        {
                            !back
                                ? <div className='w-full flex flex-col jusitfy-center items-center'>
                                    <form
                                        id='edt_usr_frm'
                                        onSubmit={e => {
                                            e.preventDefault()
                                            _handleSubmit()
                                        }}

                                        className='w-full max-w-lg grid grid-cols-1 gap-4 py-2'
                                    >
                                        <FormControl>
                                            <InputLabel htmlFor='name'>Nome Completo</InputLabel>
                                            <Input
                                                id='name'
                                                aria-describedby='nome completo do usuário'
                                                value={form.data.name ?? ''}
                                                onChange={e => setForm({ ...form, data: { ...form.data, name: e.target.value } })}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <InputLabel htmlFor='email'>E-mail*</InputLabel>
                                            <Input
                                                id='email'
                                                aria-describedby='email de usuário'
                                                value={form.email ?? ''}
                                                onChange={e => setForm({ ...form, email: e.target.value })}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <InputLabel htmlFor='usr_name'>Nome de usuário</InputLabel>
                                            <Input
                                                id='usr_name'
                                                aria-describedby='nome de usuário'
                                                value={form.username ?? ''}
                                                onChange={e => setForm({ ...form, username: e.target.value })}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <InputLabel htmlFor='usr_department'>Departamento</InputLabel>
                                            <Input
                                                id='usr_department'
                                                aria-describedby='cargo do usuário'
                                                value={form.data.department ?? ''}
                                                onChange={e => setForm({ ...form, data: { ...form.data, department: e.target.value } })}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <InputLabel htmlFor='usr_role'>Cargo</InputLabel>
                                            <Input
                                                id='usr_role'
                                                aria-describedby='cargo do usuário'
                                                value={form.data.role ?? ''}
                                                onChange={e => setForm({ ...form, data: { ...form.data, role: e.target.value } })}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <InputLabel htmlFor='usr_info'>Informações</InputLabel>
                                            <Input
                                                id='usr_info'
                                                aria-describedby='informações do usuário'
                                                value={form.data.additional_info ?? ''}
                                                onChange={e => setForm({ ...form, data: { ...form.data, additional_info: e.target.value } })}
                                            />
                                        </FormControl>

                                        <div className='w-full flex justify-end items-center'>

                                            <Button
                                                type='submit'
                                                variant='contained'
                                            >Salvar</Button>
                                        </div>

                                    </form>
                                </div>
                                : <UserPermissions
                                    user={form.id}
                                    abilities={form.permissions.abilities}
                                />
                        }
                    </>

            }
        </>

    )
}
