import { createTheme } from "@mui/material";

export const button = createTheme({
  palette: {
    primary: {
      main: "#005baa",
      contrastText: '#f7f7f7',

    },
    secondary: {
      main: '#535252',
      contrastText: '#f7f7f7',

    },
    neutral: {
      main: '#535252',
      contrastText: '#f7f7f7',

    },
    main: {
      main: '#004780',
      contrastText: '#f7f7f7'
    },
    neutral_blue: {
      main: '#3d54df',
      contrastText: '#f7f7f7',

    },
    neutral_green: {
      main: '#23b893',
      contrastText: '#f7f7f7',

    },
    confirm: {
      main: "#10b981",
      contrastText: '#f7f7f7',

    },
    cancel: {
      main: "#ef4444",
      contrastText: '#f7f7f7',

    },
    white: {
      main: '#ffffff',
      contrastText: '#ffffff',
    }
  },
});