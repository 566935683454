import React from 'react'
import { useMatch } from 'react-router-dom'
import Page from '../../layout/Page'
import ManagementTabs from '../../shared/components/ManagementTabs'
import { UsersContextProvider } from './components/Context'

export default function Users() {

  const create = useMatch('configuracoes/usuarios/novo')
  const settings = useMatch('configuracoes/usuarios/')

  return (
    <UsersContextProvider>
      <Page title='Usuários'>
        <ManagementTabs path={'/configuracoes/usuarios'} create={create} settings={settings} />
      </Page>
    </UsersContextProvider>
  )
}
