import { NoteAdd, Search, SearchOff } from '@mui/icons-material'
import { Button, Collapse, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppContext } from '../../../context/AppContextProvider'
import { toastError, ufList } from '../../../shared/scripts/utilities'
import { OrdersContext } from './Context'

export default function SearchOrders() {
    const appCtx = useContext(AppContext)
    const ctx = useContext(OrdersContext)
    const navigate = useNavigate()
    const defaultForm = {
        from_location: '',
        to_location: '',
        sender: '',
        recipient: '',
        order: '',
        cte: '',
        date_ini: '',
        date_end: '',
        status: '',
    }

    const senders = [
        'DF - Ministério da Saúde',
    ]
    const recipients = [
        'AC - Secretaria do Estado de Saúde do Acre',
    ]

    const [searchOpen, setSearchOpen] = useState(false)
    const [form, setForm] = useState(defaultForm)

    // handlers
    const _handleSubmit = async e => {
        e.preventDefault()
        try {
            const resp = await ctx.axios.post(_getQuery())
            toast.success(resp.data.message)
            ctx.update()
        } catch (err) {
            toastError(err)
        }

    }

    // functions

    function _getQuery() {
        let baseUrl = '/api/orders/?'
        _.forEach(form, (v, k) => {
            if (!v) return
            baseUrl = baseUrl + k + '=' + v + '&'
        })
        baseUrl = baseUrl.slice(0, -1);
        return baseUrl;
    }

    return (
        <div className='w-full flex-col py-2 px-6'>
            <div className='w-full pl-2 flex flex-col justify-center items-center sm:flex-row sm:justify-start '>
                {
                    appCtx.hasAbility('pedidos:write')
                        ? <Tooltip
                            title='Cadastrar novo pedido'
                        >
                            <Button
                                variant='outlined'
                                color='primary'
                                startIcon={<NoteAdd />}
                                onClick={e => navigate('/pedidos/novo')}
                                sx={{
                                    height: 40,
                                    width: 260,
                                }}
                            >
                                Novo Pedido
                            </Button>
                        </Tooltip>
                        : null
                }

                <Tooltip
                    title='Buscar pedidos'
                >
                    <Button
                        color='neutral'
                        startIcon={searchOpen ? <SearchOff /> : <Search />}
                        onClick={e => setSearchOpen(!searchOpen)}
                        sx={{
                            height: 40,
                            width: 260,
                            marginLeft: window.screen.width < 640 ? 0 : '1rem',
                        }}
                    >
                        Buscar Pedidos
                    </Button>
                </Tooltip>
            </div>

            <div className='w-full border-b pb-2 mb-2'>
                <Collapse in={searchOpen}>
                    <form
                        id='src_ord_form'
                        onSubmit={_handleSubmit}
                        className='w-full'
                    >
                        <div className='w-full grid gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 p-2'>
                            <TextField
                                label='NF / Pedido'
                                value={form.order}
                                onChange={e => setForm({ ...form, order: e.target.value })}
                            />

                            <FormControl sx={{ minWidth: 90 }}>
                                <InputLabel id='slt_org'>Origem (Cidade / UF)</InputLabel>
                                <Select
                                    label='Origem (Cidade / UF)'
                                    labelId='slt_org'
                                    value={form.from_location}
                                    onChange={e => setForm({ ...form, from_location: e.target.value })}
                                >
                                    <MenuItem value='' disabled selected>Selecione</MenuItem>
                                    {
                                        ufList.map(i => (<MenuItem key={i} value={i}>{i}</MenuItem>))
                                    }
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 90 }}>
                                <InputLabel id='slt_dst'>Destino (Cidade / UF)</InputLabel>
                                <Select
                                    label='Destino (Cidade / UF)'
                                    labelId='slt_dst'
                                    value={form.to_location}
                                    onChange={e => setForm({ ...form, to_location: e.target.value })}
                                >
                                    <MenuItem value='' disabled selected>Selecione</MenuItem>
                                    {
                                        ufList.map(i => (<MenuItem key={i} value={i}>{i}</MenuItem>))
                                    }
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 90 }}>
                                <InputLabel id='slt_snd'>Remetente</InputLabel>
                                <Select
                                    label='Remetente'
                                    labelId='slt_snd'
                                    value={form.sender}
                                    onChange={e => setForm({ ...form, sender: e.target.value })}
                                >
                                    <MenuItem value='' disabled selected>Selecione</MenuItem>
                                    {
                                        senders.map(i => (<MenuItem key={i} value={i}>{i}</MenuItem>))
                                    }
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 90 }}>
                                <InputLabel id='slt_rcp'>Destinatário</InputLabel>
                                <Select
                                    label='Destinatário'
                                    labelId='slt_rcp'
                                    value={form.recipient}
                                    onChange={e => setForm({ ...form, recipient: e.target.value })}
                                >
                                    <MenuItem value='' disabled selected>Selecione</MenuItem>
                                    {
                                        recipients.map(i => (<MenuItem key={i} value={i}>{i}</MenuItem>))
                                    }
                                </Select>
                            </FormControl>

                            <TextField
                                label='CTe / Minuta'
                                value={form.cte}
                                onChange={e => setForm({ ...form, cte: e.target.value })}
                            />

                            <TextField
                                label='Data Inicial'
                                InputLabelProps={{ shrink: true }}
                                type='date'
                                value={form.date_ini}
                                onChange={e => setForm({ ...form, date_ini: e.target.value })}

                            />

                            <TextField
                                label='Data Final'
                                InputLabelProps={{ shrink: true }}
                                type='date'
                                value={form.date_end}
                                onChange={e => setForm({ ...form, date_end: e.target.value })}
                            />

                            <FormControl sx={{ minWidth: 110 }}>
                                <InputLabel id='slt_sts'>Status Frete</InputLabel>
                                <Select
                                    label='Status Frete'
                                    labelId='slt_sts'
                                    value={form.status}
                                    onChange={e => setForm({ ...form, status: e.target.value })}
                                >
                                    <MenuItem value='' disabled selected>Selecione</MenuItem>
                                    {
                                        ctx.statusList.map(i => (<MenuItem key={i} value={i}>{i}</MenuItem>))
                                    }
                                </Select>
                            </FormControl>
                        </div>


                        <div className='w-full flex justify-center items-center sm:justify-end'>
                            <div className='grid gap-2 sm:grid-cols-2'>
                                <Button
                                    type='reset'
                                    onClick={e => setForm(defaultForm)}
                                    color='error'
                                    variant='outlined'
                                    sx={{ width: 240 }}
                                >Limpar</Button>

                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='main'
                                    sx={{ width: 240 }}
                                >Buscar</Button>
                            </div>
                        </div>
                    </form>
                </Collapse>
            </div>
        </div>
    )
}
