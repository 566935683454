import React, { useContext } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { AppContext } from '../../context/AppContextProvider'

export default function ManagementTabs({ path, settings, create }) {
    const appCtx = useContext(AppContext)

    return (
        <div className='w-full flex flex-col items-center justify-center font-extralight'>
            <nav
                className='w-full flex justify-start items-center text-white ms-blue px-2'
            >
                <Link
                    to={path}
                    replace
                    className={`h-full w-20 text-center py-4 px-1 transition-all  ${settings ? 'bg-white text-black hover:decoration' : 'hover:underline'}`}
                >Gerenciar</Link>

                {
                    appCtx.hasAbility(`${path}:write`)
                        ? <Link
                            to='novo'
                            replace
                            className={`h-full w-20 text-center py-4 px-1 transition-all  ${create ? 'bg-white text-black' : 'hover:underline'}`}
                        >Novo</Link>
                        : null
                }

            </nav>

            <div className='w-full'>
                <Outlet />
            </div>

        </div>
    )
}
