import React from 'react'
import { Link, useMatch } from 'react-router-dom'

export default function MenuOption({ route, onClose }) {
    const match = useMatch(route.path + '/*')
    return (
        <Link
            to={route.path}
            className={`w-full text-end pr-8 py-2 font-semibold capitalize border-l-4`}
            style={{
                backgroundColor: match ? 'rgba(0, 91, 170, .2 )' : 'transparent',
                borderColor: match ? 'rgba(0, 91, 170, .8 )' : 'transparent',
               
            }}
            onClick={e => onClose(false)}
        >{route.name}</Link>
    )
}
