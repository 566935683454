import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useEffect } from 'react'
import '../../../styles/App.css';
import { routes } from '../../config/routes';
import { AppContext } from '../../context/AppContextProvider';
import Page from '../../layout/Page';
import Nav from '../../shared/components/Nav';

export default function Home() {
  const appCtx = useContext(AppContext)
  const stack = appCtx.allowed_routes.filter(r => r.name !== 'home' && r.name !== '404')

  useEffect(() => {
    appCtx.fetchUser();
    return () => {
      let ac = new AbortController();
      ac.abort();
    }
  }, [])
  return (
    <>
      {
        (!_.isEmpty(appCtx.userInfo) && appCtx.loggedIn && appCtx.autoCookie)
          ? <Page title='Home'>
            {
              _.isEmpty(appCtx.userInfo)
                ? <div className='w-full flex py-2 justify-center'><CircularProgress /></div>
                : <div className='w-full flex flex-col justify-center items-center z-20'>
                  <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                    Bem-vindo(a),
                    <span className='ml-2 capitalize'>{appCtx.userInfo.data.name ?? appCtx.userInfo.username.replace('.', ' ')}</span>
                  </h3>
                  {
                    stack.length
                      ? < Nav stack={stack} />
                      : <span className='w-full text-center text-gray-700'>Você ainda não possui acessos definidos, contate o administrador.</span>
                  }
                </div>
            }
          </Page>
          : null
      }
    </>
  );
}
