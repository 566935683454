import { CheckCircle } from '@mui/icons-material'
import { LinearProgress, Step, StepContent, StepLabel, Stepper } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { toastError } from '../../../shared/scripts/utilities'
import { OrdersContext } from './Context'

export default function OrderTracking({ rowId }) {

    const ctx = useContext(OrdersContext)

    const [steps, setSteps] = useState([])
    const [pending, setPending] = useState(true)


    // Fetchs

    const _fetchTracks = async () => {
        try {
            const resp = await ctx.axios.get(`/api/orders/${rowId}/tracking`)
            setSteps(resp.data)
            setPending(false)
        } catch (err) {
            toastError(err)
            setPending(false)
        }
    }

    useEffect(() => {
        _fetchTracks()
        return () => {
            const ac = new AbortController()
            ac.abort()
        }
    }, [])

    return (
        <>
            {
                pending
                    ? <LinearProgress />
                    : <div className='w-full flex flex-col items-center'>
                        <h2 className='w-full text-center text-xl mb-6'>Rastreamento</h2>
                        {
                            _.isEmpty(steps)
                                ? <span className='w-full text-center'>Nenhum resultado disponível</span>
                            : <Stepper activeStep={0} orientation='vertical'>
                                    {
                                        _.orderBy(steps, 'DAT_OCO', 'desc').map((t, idx) => {
                                            let date = new Date(t.DAT_OCO)
                                            let dateTime = new Intl.DateTimeFormat('pt-br', { day: 'numeric', month: 'numeric', year: '2-digit', hour: 'numeric', minute: 'numeric' }).format(date)
                                            return (<Step key={idx}>
                                                <StepLabel icon={<span className={idx === 0 ? 'text-blue-800' : 'text-gray-600'}><CheckCircle /></span>}>
                                                    <span className='text-sm'>{t.DES_OCO}</span>
                                                    <span className=''>{` - ${dateTime}`}</span>
                                                </StepLabel>
                                            </Step>)
                                        })
                                    }
                                </Stepper>
                        }
                    </div>
            }
        </>
    )
}
