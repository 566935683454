import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import LoginPage from './app/pages/login/LoginPage';
import App from './app/layout/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
require('./app/bootstrap/bootstrap');

(async () => {
  await window.axios.get('/sanctum/csrf-cookie');

  const root = ReactDOM.createRoot(document.getElementById('rootApp'));
  root.render(<App />);

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.unregister();
})();