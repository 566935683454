import { CircularProgress, FormControl, Input, InputLabel, MenuItem, Select } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import DataTable from 'react-data-table-component'
import { dataTable } from '../../../context/DataTableConfig'
import Page from '../../../layout/Page'
import { formatString } from '../../../shared/scripts/utilities'
import { OrdersContext } from '../components/Context'
import Control from '../components/Control'
import OrderActions from '../components/OrderActions'
import SearchOrders from '../components/SearchOrders'

export default function OrdersTable() {

    const ctx = useContext(OrdersContext)

    const [search, setSearch] = useState('')

    const filtered = ctx.orders.filter(item => {
        if (
            formatString(item.order ?? '-').indexOf(formatString(search)) !== -1 ||
            formatString(item.modal ?? '-').indexOf(formatString(search)) !== -1 ||
            formatString(item.company.name ?? '-').indexOf(formatString(search)) !== -1 ||
            formatString(item.program.name ?? '-').indexOf(formatString(search)) !== -1 ||
            formatString(item.recipient.name ?? '-').indexOf(formatString(search)) !== -1 ||
            formatString(item.classification.name ?? '-').indexOf(formatString(search)) !== -1 ||
            formatString(item.agenda.name ?? '-').indexOf(formatString(search)) !== -1 ||
            formatString(item.recipient.uf ?? '-').indexOf(formatString(search)) !== -1 ||
            formatString(item.current_status ?? '-').indexOf(formatString(search)) !== -1

        )
            return true
        else
            return false
    });

    const columns = [
        {
            name: 'Pedido',
            cell: row => row.order,
            selector: row => JSON.stringify(row.order),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Modal',
            cell: row => row.modal ?? <Select
                name={`${row.id}`}
                defaultValue={''}
                displayEmpty
                variant='standard'
                onChange={e => _changelModal(row.id, e.target.value)}
            >
                <MenuItem value='' selected disabled>Selecione</MenuItem>
                {
                    ctx.modals.map(modal => (<MenuItem key={modal} value={modal}>{modal}</MenuItem>))
                }
            </Select>,
            selector: row => JSON.stringify(row.modal),
            sortable: true,
            reorder: true,
            grow: 2,
            center: true,
        },
        {
            name: 'Empresa',
            cell: row => row.company.name,
            selector: row => JSON.stringify(row.company.name),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Programa',
            cell: row => row.program.name,
            selector: row => JSON.stringify(row.program.name),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Nome',
            cell: row => row.recipient.name ?? '-',
            selector: row => row.recipient.name ?? '-',
            sortable: true,
            reorder: true,
            grow: 7,
        },
        {
            name: 'Tipo',
            cell: row => row.classification.name ?? '-',
            selector: row => row.classification.name ?? '-',
            sortable: true,
            reorder: true,
        },
        {
            name: 'Pauta',
            cell: row => row.agenda.name,
            selector: row => JSON.stringify(row.agenda.name),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Quant.',
            cell: row => row.items[0].auth_quantity,
            selector: row => JSON.stringify(row.items[0].auth_quantity),
            sortable: true,
            reorder: true,
        },
        {
            name: 'UF',
            cell: row => row.recipient.uf ?? '-',
            selector: row => JSON.stringify(row.recipient.uf ?? '-'),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Solicit. Agend.',
            cell: row => _formatDate(row.scheduled_date),
            selector: row => JSON.stringify(_formatDate(row.scheduled_date) ?? '-'),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Confirm. Agend.',
            cell: row => _formatDate(row.confirmed_date),
            selector: row => JSON.stringify(_formatDate(row.confirmed_date) ?? '-'),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Total',
            cell: row => parseFloat(row.order_total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
            selector: row => JSON.stringify(row.order_total),
            sortable: true,
            reorder: true,
            grow: 1.5,
        },
        {
            name: 'Situação',
            cell: row => _.isEmpty(row.status) ? row.current_status : _.orderBy(row.status, 'status_dat', 'desc')[0].status_desc,
            selector: row => JSON.stringify(_.isEmpty(row.status) ? row.current_status : _.orderBy(row.status, 'status_dat', 'desc')[0].status_desc),
            sortable: true,
            reorder: true,
            grow: 3,
        },
        {
            name: 'Ações',
            cell: row => <OrderActions row={row} />,
            selector: row => JSON.stringify(row.situation),
            grow: 4,
            center: true,
        },
    ]

    const conditionalStyles = [
        {
            when: row => row.current_status === 'Aprovado',
            style: {
                backgroundColor: '#8bd9a0'
            }
        },
        {
            when: row => row.current_status === 'Aguardando aprovação',
            style: {
                backgroundColor: '#b3b3b3'
            }
        },
    ]

    // handlers

    function _handleSelection({ selectedRows }) {
        ctx.setClearSelected(false);
        let copy = _.uniqBy(selectedRows, 'id').map(order => {
            let orderCopy = Object.assign({}, order);
            if (!orderCopy.modal && orderCopy.selected_modal) orderCopy.modal = orderCopy.selected_modal;
            return orderCopy;
        })
        ctx.setSelectedRows(copy)
    }

    const _changelModal = (id, modal) => {
        ctx.clear()
        let order = ctx.orders.find(o => o.id === id)
        order.selected_modal = modal
        const updatedOrders = _.uniqBy([...ctx.orders, order], 'id')
        ctx.setOrders(updatedOrders)
    }

    // functions
    function _formatDate(stg) {
        let date = new Date(stg)
        return date.toLocaleDateString('pt-br', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        })
    }

    return (
        <Page title='Pedidos'>
            <div className='w-full flex flex-col justify-center'>
                <div className='w-full flex-col justify-center items-center'>
                    <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                        Tabela de Pedidos
                    </h3>
                </div>
                <SearchOrders />
                <DataTable
                    keyField='Pedido'
                    data={filtered}
                    columns={columns}
                    subHeader
                    subHeaderAlign={'right'}
                    subHeaderComponent={<div className='w-full flex flex-col md:flex-row md:items-end md:justify-end'>
                        <Control />
                        <FormControl>
                            <InputLabel htmlFor='srch_ord'>Buscar</InputLabel>
                            <Input
                                id='srch_ord'
                                value={search}
                                onChange={e => setSearch(e.target.value)} />
                        </FormControl>
                    </div>}
                    noDataComponent={dataTable.options.noDataComponent}
                    customStyles={dataTable.theme.default}
                    conditionalRowStyles={conditionalStyles}
                    pagination
                    paginationComponentOptions={dataTable.options.paginationConponentOptions}
                    sortIcon={dataTable.options.sortIcon}
                    sortFunction={dataTable.options.sortFunction}
                    defaultSortFieldId='Pedido'
                    defaultSortAsc={false}
                    fixedHeader
                    selectableRows
                    onSelectedRowsChange={_handleSelection}
                    clearSelectedRows={ctx.clearSelected}
                    // progressPending={ctx.pending}
                    progressPending={!ctx.orders.length}
                    progressComponent={<CircularProgress />}
                />

            </div>
        </Page>
    )
}
