import { Drawer, IconButton, Toolbar } from '@mui/material'
import { Menu as MenuIcon, PowerSettingsNew } from '@mui/icons-material'
import React, { useContext, useState } from 'react'
import RouteList from './components/RouteList';
import IBL_logo from '../../assets/png/Grupo_IBL_black.png'
import { AppContext } from '../context/AppContextProvider';
import { Link, useNavigate } from 'react-router-dom';

export default function Navigation() {
    const appCtx = useContext(AppContext)
    const [openMenu, setOpenMenu] = useState(false);

    // handlers
    const logout  = () => {
        appCtx.logout()
    }
    const _handleDrawerToggle = () => {
        setOpenMenu(!openMenu);
    }
    
    return (
        <Toolbar
            id='toolbar'
            variant='dense'
            sx={{
                width: '100%',
                backgroundColor: '#f7f7f7',
                boxShadow: '0 1px 1px #e5e5e5',
            }}
        >
            <div className='w-full flex justify-between items-center ' >
                <IconButton onClick={_handleDrawerToggle}>
                    <MenuIcon sx={{
                        color: 'rgb(9,71,128)',

                    }} />
                </IconButton>
                <div className='flex-1 flex justify-center select-none'>
                    <Link to='/' className='hidden sm:flex lg:ml-52 py-4'>
                        <img src={IBL_logo} className='h-14' alt='Logo do grupo IBL' />
                        <div className='flex flex-col justify-center items-start border-l-2 pl-2 border-gray-600'>
                            <span className='font-bold text-xl text-gray-900'>GRUPO IBL</span>
                            <span className='font-bold text-lg text-gray-900'>RADAR VACINA</span>
                        </div>
                    </Link>
                </div>
                <div className='flex flex-col items-end'>
                    <span>{appCtx.userInfo.email}</span>
                    <span
                        className='flex items-center cursor-pointer'
                        onClick={logout}
                    >
                        Sair
                        <PowerSettingsNew fontSize='small' sx={{marginLeft: 1}}/>
                    </span>
                </div>
            </div>
            <Drawer
                open={openMenu}
                onClose={_handleDrawerToggle}
            >
                <RouteList onClose={setOpenMenu} />
            </Drawer>
        </Toolbar>
    )
}
