window.axios = require('axios');
window._ = require('lodash');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.withCredentials = true;
window.axios.defaults.baseURL = 'https://ministerio.ibllogistica.com.br';

// export function api() {
//     const api = axios.create({ 
//         baseURL: 'https://ministerio.ibllogistica.com.br',
//         withCredentials: true,
//      })

//     api.interceptors.response.use(resp => resp, err => {
//         if (err.response.status === 401) {
//             logOut()
//             return Promise.reject();
//         }

//         return Promise.reject(err);
//     })

//     api.interceptors.request.use(req => {
//         if(!Boolean(Cookies.get('radar_vacina_session_validation')))
//     }, err => {
//         if (err.response.status === 401) {
//             logOut()
//             return Promise.reject();
//         }

//         return Promise.reject(err);
//     })

//     return api;
// }