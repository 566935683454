import _ from "lodash";
import { toast } from "react-toastify";

export function formatString(stg) {
    if (typeof stg != 'string') stg = JSON.stringify(stg)
    stg = stg.toLowerCase();
    stg = stg.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    stg = stg.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    stg = stg.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    stg = stg.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    stg = stg.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    stg = stg.replace(new RegExp('[Ç]', 'gi'), 'c');
    return stg;
}

export function toastError(err) {
    if (_.has(err, 'response.data.errors')) {
        Object
            .values(err.response.data.errors)
            .forEach(error => error
                .forEach(message => toast.error(message)));
    } else if (_.has(err, 'response.data.message')) {
        err.response.data.message === 'Unauthenticated.'
            ? toast.error('Falha ao autenticar')
            : toast.error(err.response.data.message)
    }

}

export function parseDate(data) {
    let date = new Date(data)
    return date.toLocaleDateString('pt-br', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    })
}

export const ufList = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MS',
    'MT',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
]