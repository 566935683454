import { LinearProgress } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { parseDate, toastError } from '../../../shared/scripts/utilities'
import { OrdersContext } from './Context'

export default function TemperatureTracking({ rowId }) {
    const ctx = useContext(OrdersContext)

    const [data, setData] = useState([])
    const [pending, setPending] = useState(true)

    const columns = [
        {
            name: 'Dispositivo',
            cell: row => row.device_id,
            center: true,
        },
        {
            name: 'Temperatura',
            cell: row => row.temperature / 10 + 'ºC',
            center: true,
        },
        {
            name: 'Horário Leitura',
            cell: row => {
                let date = new Date(parseInt(row.time) * 1000)
                return parseDate(date);
            },
            center: true,
        }
    ]

    // Fetchs

    const _fetchLogs = async () => {
        try {
            const resp = await ctx.axios.get(`/api/orders/${rowId}/temperatures`)
            let result = []
            _.forEach(resp.data, i => result.push(i[0]))
            setData(result)
            setPending(false)
        } catch (err) {
            toastError(err)
            setPending(false)
        }
    }

    useEffect(() => {
        _fetchLogs()
        return () => {
            const ac = new AbortController()
            ac.abort()
        }
    }, [])

    return (
        <>
            {
                pending
                    ? <LinearProgress />
                    : <div className='w-full'>
                        <h2 className='w-ful text-center text-xl mb-6'>Temperaturas</h2>
                        <DataTable
                            data={data}
                            columns={columns}
                            noDataComponent={<span className='w-full text-center'>Nenhum resultado disponível</span>}
                            striped
                        />
                    </div>
            }
        </>
    )
}
