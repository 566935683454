import React from 'react'
import { useMatch } from 'react-router-dom'
import Page from '../../layout/Page'
import ManagementTabs from '../../shared/components/ManagementTabs'
import { ProgramsContextProvider } from './components/Context'

export default function Programs() {

  const create = useMatch('programas/novo')
  const settings = useMatch('programas/')

  return (
    <ProgramsContextProvider>
      <Page title='Programas'>
          <ManagementTabs path={'/programas'} create={create} settings={settings} />
      </Page>
    </ProgramsContextProvider>
  )
}
