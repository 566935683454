import { Assignment, Bookmarks, Category, PendingActions, Settings as GearIcon } from "@mui/icons-material";
import { Route } from "react-router-dom";
import Agendas from "../pages/agenda";
import CreateAgenda from "../pages/agenda/components/forms/CreateAgenda";
import AgendasTable from "../pages/agenda/components/tables/Agendas";
import Classifications from "../pages/classifications";
import CreateClassification from "../pages/classifications/components/forms/CreateClassification";
import ClassificationsTable from "../pages/classifications/components/tables/Classifications";
import Corporations from "../pages/corporations";
import CreateCorporation from "../pages/corporations/components/forms/CreateCorporation";
import CorporationsTable from "../pages/corporations/components/tables/Corporations";
import Home from "../pages/home";
import Mails from "../pages/mails";
import CreateMail from "../pages/mails/components/forms/CreateMail";
import MailsTable from "../pages/mails/components/tables/Mails";
import Orders from "../pages/orders";
import CreateOrder from "../pages/orders/components/CreateOrder";
import OrdersTable from "../pages/orders/tables/Orders";
import Programs from "../pages/programs";
import CreateProgram from "../pages/programs/components/forms/CreateProgram";
import ProgramsTable from "../pages/programs/components/tables/Programs";
import Settings from "../pages/settings";
import Users from "../pages/users";
import CreateUser from "../pages/users/components/forms/CreateUser";
import UsersTable from "../pages/users/components/tables/Users";


export const routes = [
    {
        path: '/',
        name: 'home',
        element: <Home />,
        enabled: true
    },
    {
        path: 'pedidos',
        name: 'pedidos',
        enabled: true,
        description: 'Gerenciar pedidos',
        icon: <Assignment />,
        element: <Orders />,
        children: <>
            <Route key='Pedidos' index element={<OrdersTable />} />
            <Route key='Novo' path='novo' element={<CreateOrder />} />
        </>

    },
    {
        path: 'classificacoes',
        name: 'classificações',
        enabled: true,
        description: 'Criar e gerenciar classificações',
        icon: <Category />,
        element: <Classifications />,
        children: <>
            <Route key='Gerenciar' index element={<ClassificationsTable />} />
            <Route key='Novo' path='novo' element={<CreateClassification />} />
        </>,
    },
    {
        path: 'pautas',
        name: 'pautas',
        enabled: true,
        description: 'Criar e gerenciar pautas',
        icon: <PendingActions />,
        element: <Agendas />,
        children: <>
            <Route key='Gerenciar' index element={<AgendasTable />} />
            <Route key='Novo' path='novo' element={<CreateAgenda />} />
        </>,
    },
    {
        path: 'programas',
        name: 'programas',
        enabled: true,
        description: 'Criar e gerenciar programas',
        icon: <Bookmarks />,
        element: <Programs />,
        children: <>
            <Route key='Gerenciar' index element={<ProgramsTable />} />
            <Route key='Novo' path='novo' element={<CreateProgram />} />
        </>,
    },
    {
        path: 'configuracoes',
        name: 'configurações',
        enabled: true,
        description: 'Gerenciar configurações da aplicação',
        icon: <GearIcon />,
        children: <>
            <Route key='idx' index element={<Settings />} />
            <Route key='Usuários' path='usuarios' element={<Users />}>
                <Route key='Gerenciar' index element={<UsersTable />} />
                <Route key='Novo' path='novo' element={<CreateUser />} />
            </Route>
            <Route key='Empresas' path='empresas' element={<Corporations />}>
                <Route key='Gerenciar' index element={<CorporationsTable />} />
                <Route key='Novo' path='novo' element={<CreateCorporation />} />
            </Route>
            <Route key='Emails' path='emails' element={<Mails />}>
                <Route key='Gerenciar' index element={<MailsTable />} />
                <Route key='Novo' path='novo' element={<CreateMail />} />
            </Route>
        </>,
    },
];
