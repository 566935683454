import React, { createContext, useContext, useState } from 'react'
import { AppContext } from '../../../context/AppContextProvider'
import { toastError } from '../../../shared/scripts/utilities'

export const MailsContext = createContext(null)
export function MailsContextProvider({ children }) {
    const appCtx = useContext(AppContext)
    const [mails, setMails] = useState([])
    const [pending, setPending] = useState(false)

    const _fetchMails = async () => {
        try {
            setPending(true)
            const resp = await appCtx.axios.get('/api/settings/mailing')
            setMails(resp.data)
            setPending(false)
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <MailsContext.Provider
            value={{
                mails,
                setMails,
                pending,
                axios: appCtx.axios,
                update: _fetchMails,
            }}
        >
            {children}
        </MailsContext.Provider>
    )
}
