import { Button, Fade, FormControl, Input, InputLabel } from '@mui/material'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { toastError } from '../../../../shared/scripts/utilities'
import { UsersContext } from '../Context'
import UserPermissions from './UserPermissions'

export default function CreateUser() {
    const ctx = useContext(UsersContext)

    const [form, setForm] = useState({
        username: '',
        password: 'ibl@1234',
        email: '',
    })

    const _handleSubmit = async () => {
        try {
            const resp = await ctx.axios.post('/api/auth/register', form)
            toast.success(resp.data.message)
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <>
            <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                Novo Usuário
            </h3>
            <Fade in={true}>

                <div className='w-full flex flex-col jusitfy-center items-center'>
                    <form
                        id='crt_usr_frm'
                        onSubmit={e => {
                            e.preventDefault()
                            _handleSubmit()
                        }}

                        className='w-full max-w-lg grid grid-cols-1 gap-4 py-2'
                    >
                        <FormControl>
                            <InputLabel htmlFor='email'>E-mail*</InputLabel>
                            <Input
                                id='email'
                                aria-describedby='email de usuário'
                                value={form.email}
                                onChange={e => setForm({ ...form, email: e.target.value })}
                            />
                        </FormControl>

                        <FormControl>
                            <InputLabel htmlFor='usr_name'>Nome de usuário</InputLabel>
                            <Input
                                id='usr_name'
                                aria-describedby='nome de usuário'
                                value={form.username}
                                onChange={e => setForm({ ...form, username: e.target.value })}
                            />
                        </FormControl>

                        <h3 className='w-full text-semibold text-center text-gray-600 my-2 text-2xl'>
                            Permissões
                        </h3>

                        <UserPermissions
                            user={''}
                            abilities={''}
                            form={form}
                            clearForm={() => setForm({
                                username: '',
                                password: 'ibl@1234',
                                email: '',
                            })}
                        />

                    </form>
                </div>
            </Fade>
        </>
    )
}
