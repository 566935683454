import React from 'react'
import { useMatch } from 'react-router-dom'
import Page from '../../layout/Page'
import ManagementTabs from '../../shared/components/ManagementTabs'
import { MailsContextProvider } from './components/Context'

export default function Mails() {

  const create = useMatch('configuracoes/emails/novo')
  const settings = useMatch('configuracoes/emails/')

  return (
    <MailsContextProvider>
      <Page title='Usuários'>
        <ManagementTabs path={'/configuracoes/emails'} create={create} settings={settings} />
      </Page>
    </MailsContextProvider>
  )
}
