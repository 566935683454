import { Button, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { DropzoneAreaBase } from 'react-mui-dropzone'
import Page from '../../../layout/Page'
import '../../../../styles/Dropzone.css';
import { toastError } from '../../../shared/scripts/utilities';
import { OrdersContext } from './Context';
import _ from 'lodash';
import { toast } from 'react-toastify';

export default function CreateOrder() {

  const ctx = useContext(OrdersContext)
  const [form, setForm] = useState({
    company: '',
    agenda: '',
    program: '',
    classification: '',
    files: []
  })

  const _handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (!form.files.length) return toast.warn('Insira ao menos um arquivo para criar o novo pedido.')
      let formData = new FormData()
      form.files.forEach(document => {
        formData.append('files[]', _convertFile(document), document.name);
      })
      formData.append('agenda', form.agenda)
      formData.append('classification', form.classification)
      formData.append('company', form.company)
      formData.append('program', form.program)
      const resp = await ctx.axios.post('/api/orders', formData)
      ctx.update()
      toast.success(resp.data.message)
      if (resp.data.error) toast.warn(resp.data.errorMessage)
    } catch (err) {
      console.log(err)
      toastError(err)
    }
  }

  const _handleAdd = newFiles => {
    const files = _.uniqBy([...form.files, ...newFiles], 'file.path')
    setForm({ ...form, files: files })
  }

  const _handleDelete = deletedFile => {
    const files = form.files.filter(file => !_.isEqual(file, deletedFile))
    setForm({ ...form, files: files })
  }

  function _convertFile(file) {
    let blob = new File([file.file], file.file.name, { type: file.file.type });
    return blob;
  }

  useEffect(() => {
    ctx.update()
    return () => {
      const ac = new AbortController()
      ac.abort()
    }
  }, [])

  return (
    <Page title='Pedidos'>
      <form
        id='crt_ord_form'
        name='crt_ord_form'
        onSubmit={_handleSubmit}
        className='w-full flex-col justify-center items-center px-2'
      >
        <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
          Novo Pedido
        </h3>

        <div className='w-full grid gap-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 mb-2'>
          <FormControl>
            <InputLabel id='cmp_lbl'>Empresa</InputLabel>
            <Select
              label='Empresa'
              labelId='cmp_lbl'
              id='slt_cmp'
              value={form.company}
              onChange={e => setForm({ ...form, company: parseInt(e.target.value) })}
              required
            >
              <MenuItem value='' selected disabled>Selecione</MenuItem>
              {
                ctx.companies.length
                  ? ctx.companies.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))
                  : null
              }
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id='agd_lbl'>Pauta</InputLabel>
            <Select
              label='Pauta'
              labelId='agd_lbl'
              id='slt_cmp'
              value={form.agenda}
              onChange={e => setForm({ ...form, agenda: parseInt(e.target.value) })}
              required
            >
              <MenuItem value='' selected disabled>Selecione</MenuItem>
              {
                ctx.agendas.length
                  ? ctx.agendas.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))
                  : null
              }

            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id='pgm_lbl'>Programa</InputLabel>
            <Select
              label='Programa'
              labelId='pgm_lbl'
              id='slt_cmp'
              value={form.program}
              onChange={e => setForm({ ...form, program: parseInt(e.target.value) })}
              required
            >
              <MenuItem value='' selected disabled>Selecione</MenuItem>
              {
                ctx.programs.length
                  ? ctx.programs.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))
                  : null
              }

            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id='cls_lbl'>Classificação</InputLabel>
            <Select
              label='Classificação'
              labelId='cls_lbl'
              id='slt_cmp'
              value={form.classification}
              onChange={e => setForm({ ...form, classification: parseInt(e.target.value) })}
              required
            >
              <MenuItem value='' selected disabled>Selecione</MenuItem>
              {
                ctx.classifications.length
                  ? ctx.classifications.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))
                  : null
              }

            </Select>
          </FormControl>
        </div>

        <div className='w-full flex flex-col md:flex-row'>
          <DropzoneAreaBase
            acceptedFiles={['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            maxFileSize={2000000}
            previewText=''
            dropzoneText='Arrate e solte arquivos ou clique aqui para adicionar'
            getFileAddedMessage={fileName => `Arquivo "${fileName}" adicionado.`}
            getFileRemovedMessage={fileName => `Arquivo "${fileName}" removido.`}
            getFileLimitExceedMessage={(limit) => `Número máximo de arquivos excedido. Máximo ${limit} arquivos.`}
            getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => `Arquivo "${rejectedFile.name}" recusado. Envie somente arquivos do tipo .PDF, .XLS ou .XLSX de até 2MB.`}
            filesLimit={20}
            fileObjects={form.files}
            onAdd={_handleAdd}
            onDelete={_handleDelete}
            showPreviewsInDropzone={false}
            showPreviews
            showFileNames
            useChipsForPreview
          />

        </div>

        <div className='flex-1 flex justify-end items-center my-4'>
          <Button
            type='reset'
            variant='outlined'
            onClick={e => {
              setForm({
                company: '',
                agenda: '',
                program: '',
                classification: '',
                files: []
              })
            }}
            sx={{
              marginX: 2,
            }}
          >Limpar</Button>

          <Button
            type='submit'
            variant='contained'
            disabled={ctx.pending || !form.files.length}
          >Enviar</Button>
        </div>
      </form>
    </Page>
  )
}
