import { Logout } from '@mui/icons-material'
import { Button, List, ListItem } from '@mui/material'
import React, { useContext } from 'react'
import { AppContext } from '../../context/AppContextProvider'
import MenuOption from './MenuOption'

export default function RouteList({ onClose }) {

    const appCtx = useContext(AppContext)

    return (
        <div className='flex h-full flex-col justify-between items-center'>
            <List sx={{
                width: '15vw',
                minWidth: 300,
                padding: 0
            }}>
                {
                    appCtx.allowed_routes.map(route => {
                        if (!route.enabled) return false;
                        if (!appCtx.hasAbility('admin') && route.path === 'configuracoes') return false;
                        return (
                            <ListItem
                                key={'LinkToRoute' + route.name}
                                alignItems='center'
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyItems: 'center',
                                    padding: 0,
                                    margin: 0,
                                    '&:hover': {
                                        color: 'rgba(0, 91, 170, 1)',
                                        backgroundColor: 'rgba(0, 91, 170, 0.2)',
                                        borderLeft: '4px solid rgba(0, 91, 170, 0.8)'

                                    }
                                }}
                            >
                                <MenuOption route={route} onClose={onClose} />
                            </ListItem>)
                    })
                }
            </List>
            <Button
                startIcon={<Logout />}
                fullWidth
                onClick={appCtx.logout}> Sair </Button>
        </div>
    )
}
