import React, { useContext, useState } from 'react';
import { TextField, Button } from '@mui/material'
import IBL_logo from '../../../assets/png/Grupo_IBL_black.png'
import main_bg from '../../../assets/jpg/fundo_radar.jpg'
import min_logo from '../../../assets/png/min_logo.png'
import { AppContext } from '../../context/AppContextProvider';
import { Navigate } from "react-router-dom";

export default function LoginPage() {
    const appCtx = useContext(AppContext)
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // handlers
    const _login = e => {
        e.preventDefault();
        appCtx.login({
            login: username,
            password
        }, () => {
            setUsername('');
            setPassword('');
        });
    }

    return (
        <>
            {
                
                (appCtx.loggedIn && appCtx.autoCookie)
                    ? <Navigate to={'/'} />
                    : <>
                        <div
                            className='w-full h-screen flex flex-col justify-center items-start'
                            style={{
                                backgroundImage: `url(${main_bg})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >

                            <div className=' bg-white p-4 h-full w-full md:w-1/2 lg:w-1/3 xl:w-2/6 flex flex-col justify-center items-center'>
                                <div className='flex justify-center items-center'>
                                    <img src={IBL_logo} className='h-28' alt='Logo do grupo IBL' />
                                    <div className='flex flex-col justify-center items-start'>
                                        <span className='font-bold text-2xl text-gray-900'>GRUPO IBL</span>
                                        <span className='font-bold text-xl text-gray-900'>RADAR VACINA</span>
                                    </div>
                                </div>

                                <form
                                    id="loginForm"
                                    className='w-full grid grid-cols-1 gap-4 border-b-2 border-gray-200 pb-4 px-0 sm:px-4 2xl:px-28 mb-4'
                                    onSubmit={_login}
                                >
                                    <TextField
                                        label="Usuário"
                                        type={'text'}
                                        value={username}
                                        onChange={e => setUsername(e.target.value)} />
                                    <TextField
                                        label="Senha"
                                        type={'password'}
                                        value={password}
                                        onChange={e => setPassword(e.target.value)} />
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        color='primary'
                                    >Logar</Button>
                                </form>

                                <div className='w-full flex justify-center'>
                                    <img
                                        src={min_logo}
                                        alt='Logo Ministério da Saúde'
                                        width={100}
                                        height={40}
                                    />
                                </div>
                            </div>

                        </div>
                        <footer className='w-full h-28 flex justify-center items-center bg-gray-600 text-gray-400'> IBL Tech | Grupo IBL 2022</footer>
                    </>
            }
        </ >
    );
}
