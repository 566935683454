import { Button, Tooltip } from '@mui/material'
import React, { useContext, useState } from 'react'
import { OrdersContext } from './Context'
import Confirm from '../../../shared/components/Confirm'
import { toastError } from '../../../shared/scripts/utilities'
import { Clear, Done } from '@mui/icons-material'
import { toast } from 'react-toastify'
import _ from 'lodash'

export default function Control() {
    const ctx = useContext(OrdersContext)

    const [approveOpen, setApproveOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const alerts = [
        'Os pedidos selecionados já estão sendo processados e não se encontram disponíveis para Aprovação.',
        'Os pedidos selecionados já estão sendo processados e não se encontram disponíveis para Exclusão.'
    ]

    const disableAprove = !(ctx.selectedRows.length && !_.filter(ctx.selectedRows, o => (o.sent_to_transport == 1) || o.current_status == 'Aprovado' || !o.modal).length);
    const disableDelete = !(ctx.selectedRows.length && !_.filter(ctx.selectedRows, o => o.sent_to_transport == 1).length);
    const disableCancel = !(ctx.selectedRows.length && _.filter(ctx.selectedRows, o => o.sent_to_transport != 0).length);


    const _handleApprove = async () => {
        try {
            if (!ctx.selectedRows.length) return;
            let approveList = ctx.selectedRows.map(o => {
                if (o.modal && (o.sent_to_transport != 1) && (o.current_status == 'Aguardando aprovação')) {
                    let obj = { id: o.id, modal: o.modal }
                    return obj;
                }
            })
            approveList = _.filter(approveList, null)
            const resp = await ctx.axios.post('/api/orders/approve', { order_ids: approveList })
            ctx.clear()
            ctx.update()
            toast.success(resp.data.message)
        } catch (err) {
            ctx.clear()
            toastError(err)
        }
    }

    const _handleDelete = async () => {
        try {
            let ordersToDelete = ctx.selectedRows.map(order => {
                let obj = {}
                obj.id = order.id
                return obj;
            })
            const resp = await ctx.axios.post('/api/orders/cancel', { order_ids: ordersToDelete })
            ctx.clear()
            ctx.update()
            toast.success(resp.data.message)
        } catch (err) {
            ctx.clear()
            toastError(err)
        }
    }

    return (
        <div className='flex-1 mb-2'>
            <div className='w-full flex flex-col items-center md:flex-row md:items-start'>
                <Tooltip
                    title={(disableAprove && ctx.selectedRows.length) ? alerts[0] : 'Aprovar pedidos'}
                    componentsProps={{
                        tooltip: {
                            style: {
                                backgroundColor: (disableAprove && ctx.selectedRows.length) ? 'yellow' : '#474747',
                                color: (disableAprove && ctx.selectedRows.length) ? 'black' : 'white',
                            }
                        }
                    }}
                >
                    <div className='mb-4 md:mb-0'>

                        <Button
                            variant='contained'
                            color='main'
                            startIcon={<Done />}
                            onClick={e => {
                                if (!ctx.selectedRows.length) return false
                                setApproveOpen(true)
                            }}
                            disabled={disableAprove}
                            sx={{
                                height: 40,
                                width: 260,

                            }}
                        >
                            Aprovar selecionado(s)
                        </Button>
                    </div>
                </Tooltip>


                <Tooltip
                    title={(disableDelete && ctx.selectedRows.length) ? alerts[1] : 'Excluir pedidos'}
                    componentsProps={{
                        tooltip: {
                            style: {
                                backgroundColor: (disableDelete && ctx.selectedRows.length) ? 'yellow' : '#474747',
                                color: (disableDelete && ctx.selectedRows.length) ? 'black' : 'white',
                            }
                        }
                    }}
                >
                    <div className='ml-0 md:ml-4'>
                        <Button
                            variant='outlined'
                            color='error'
                            startIcon={<Clear />}
                            onClick={e => {
                                if (!ctx.selectedRows.length) return false
                                setDeleteOpen(true)
                            }}
                            disabled={disableDelete}
                            sx={{
                                height: 40,
                                width: 260,
                            }}

                        >
                            Excluir selecionado(s)
                        </Button>
                    </div>
                </Tooltip>

            </div>

            <Confirm
                open={approveOpen}
                setOpen={setApproveOpen}
                title='Aprovar Pedidos'
                message={`Confirmar aprovação dos ${ctx.selectedRows.length} pedidos selecionados?`}
                onConfirm={_handleApprove}
            />

            <Confirm
                open={deleteOpen}
                setOpen={setDeleteOpen}
                title='Excluir Pedidos'
                message={`Confirmar aprovação dos ${ctx.selectedRows.length} pedidos selecionados?`}
                onConfirm={_handleDelete}
            />
        </div >
    )
}
