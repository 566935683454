import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { routes } from '../../../../config/routes'
import { AppContext } from '../../../../context/AppContextProvider'
import { toastError } from '../../../../shared/scripts/utilities'

export default function UserPermissions({ user, abilities, form = null, clearForm }) {

  const appCtx = useContext(AppContext)

  const [permissions, setPermissions] = useState([])
  const [programs, setPrograms] = useState([])
  const [admin, setAdmin] = useState(abilities.indexOf('admin') !== -1)

  const allRoutes = routes.filter(r => r.name !== 'home' && r.name !== '404')

  // handlers
  const _handleSumbit = async () => {
    try {
      let abilities = [];
      if (admin)
        abilities = ['admin']
      else
        abilities = permissions.map(a => {
          if (_.isEmpty(a.value)) return null;
          return `${a.key}:${a.value}`;
        }).filter(i => i)

      if (_.isEmpty(`${user}`)) {
        const create = await appCtx.axios.post('/api/auth/register', form)
        const resp = await appCtx.axios.put(`/api/users/${create.data.data.id}/permissions`, { permissions: abilities })
        toast.success(create.data.message)
        setAdmin(false)
        setPermissions([])
        clearForm()

      } else {
        const resp = await appCtx.axios.put(`/api/users/${user}/permissions`, { permissions: abilities })
        toast.success(resp.data.message)
      }

    } catch (err) {
      toastError(err)
    }
  }

  const _handleChangePermission = (value) => {
    let result = [...permissions]
    let k = value.split(':')[0]
    let v = value.split(':')[1]

    result.find(i => i.key == k)
      ? result.find(i => i.key == k).value = v
      : result.push({ key: k, value: v })

    setPermissions(result)
  }

  // functions
  const _parseValues = (values) => {
    let result = []
    values.split(',').forEach(v => {
      result.push({ key: v.split(':')[0], value: v.split(':')[1] })
    })
    setPermissions(result)
  }

  // fetchs
  const _fetchPrograms = async () => {
    try {
      const resp = await appCtx.axios.get('/api/programs')
      setPrograms(resp.data)
    } catch (err) { toastError(err) }
  }

  useEffect(() => {
    _fetchPrograms();
    _parseValues(abilities)
    return () => {
      const ac = new AbortController()
      ac.abort()
    }
  }, [])

  return (
    <div className='w-full flex flex-col' >

      <div className='w-full flex justify-end font-semibold'>
        <FormGroup>
          <FormControlLabel control={
            <Checkbox
              checked={admin}
              onChange={e => setAdmin(e.target.checked)}
            />
          } label="Administrador" />
        </FormGroup>
      </div>

      <h3 className='w-full ms-blue text-white text-center py-2 font-semibold mb-4'>Rotas</h3>
      <div className='w-full grid grid-cols-2 gap-3'>

        {
          allRoutes.map((route, idx) => (
            <div key={idx} className='w-full'>
              <FormControl fullWidth >
                <FormLabel>
                  <span
                    className='w-full text-start text-gray-900 capitalize'
                  >{route.name}</span>
                </FormLabel>
                <div className='w-full flex justify-center border-t'>
                  <RadioGroup
                    name={route.path}
                    value={
                      admin
                        ? `${route.path}:write`
                        : permissions.find(r => r.key == route.path)
                          ? `${route.path}:${permissions.find(r => r.key == route.path).value}`
                          : `${route.path}:`
                    }
                    onChange={e => _handleChangePermission(e.target.value)}
                  >
                    <FormControlLabel
                      value={`${route.path}:read`}
                      label={'Visualizar'}
                      control={<Radio />}
                      disabled={admin}
                    />

                    <FormControlLabel
                      value={`${route.path}:write`}
                      label={'Publicar'}
                      control={<Radio />}
                      disabled={admin}
                    />

                    <FormControlLabel
                      value={`${route.path}:`}
                      label={'Nenhum'}
                      control={<Radio />}
                      disabled={admin}
                    />

                  </RadioGroup>
                </div>
              </FormControl>
            </div>
          ))
        }
      </div>

      <h3 className='w-full ms-blue text-white text-center py-2 font-semibold my-4'>Programas</h3>
      <div className='w-full grid grid-cols-2 gap-3'>

        {
          programs.map((program, idx) => (
            <div key={idx} className='w-full'>
              <FormControl fullWidth >
                <FormLabel>
                  <span
                    className='w-full text-start text-gray-900 capitalize'
                  >{program.name}</span>
                </FormLabel>
                <div className='w-full flex justify-center border-t'>
                  <RadioGroup
                    name={program.name}
                    value={
                      admin
                        ? `${program.id}:write`
                        : permissions.find(p => p.key == program.id)
                          ? `${program.id}:${permissions.find(p => p.key == program.id).value}`
                          : `${program.id}:`
                    }
                    onChange={e => _handleChangePermission(e.target.value)}
                  >
                    <FormControlLabel
                      value={`${program.id}:read`}
                      label={'Visualizar'}
                      control={<Radio />}
                      disabled={admin}
                    />

                    <FormControlLabel
                      value={`${program.id}:write`}
                      label={'Publicar'}
                      control={<Radio />}
                      disabled={admin}
                    />

                    <FormControlLabel
                      value={`${program.id}:`}
                      label={'Nenhum'}
                      control={<Radio />}
                      disabled={admin}
                    />

                  </RadioGroup>
                </div>
              </FormControl>
            </div>

          ))
        }
      </div>



      <div className='w-full flex justify-end items-center'>
        <Button
          type='button'
          variant='contained'
          onClick={_handleSumbit}
        >Salvar</Button>
      </div>
    </div>

  )
}
