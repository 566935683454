import React, { createContext, useContext, useState } from 'react'
import { AppContext } from '../../../context/AppContextProvider'
import { toastError } from '../../../shared/scripts/utilities'

export const AgendasContext = createContext(null)
export function AgendasContextProvider({ children }) {
    const appCtx = useContext(AppContext)
    const [agendas, setAgendas] = useState([])
    const [pending, setPending] = useState(false)


    const _fetchAgendas = async () => {
        try {
            setPending(true)
            const resp = await appCtx.axios.get('/api/agendas')
            setAgendas(resp.data)
            setPending(false)
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <AgendasContext.Provider
            value={{
                agendas,
                setAgendas,
                pending,
                axios: appCtx.axios,
                update: _fetchAgendas,
            }}
        >
            {children}
        </AgendasContext.Provider>
    )
}
