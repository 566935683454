import { Fade } from '@mui/material'
import React from 'react'
import PageTitle from './components/PageTitle'

export default function Page({ children }) {
    
    return (
        <Fade in={true}>
            <div className='w-full flex flex-col justify-center items-center'>
                <PageTitle />
                <main className='w-full h-full flex flex-col justify-start items-center bg-gray-100 overflow-y-auto box-border'>
                    <div className='w-full bg-white rounded-sm shadow-sm grid grid-cols-1 gap-2 pb-2'>
                        {children}
                    </div>
                </main>
            </div >
        </Fade>
    )
}
