import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, IconButton, TextField } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { AppContext } from '../../context/AppContextProvider'
import { toastError } from '../../shared/scripts/utilities'
import main_bg from '../../../assets/jpg/fundo_radar.jpg'


export default function ChangePwPage() {

    const appCtx = useContext(AppContext)

    const [showPw, setShowPw] = useState(false)
    const [showConf, setShowConf] = useState(false)

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmation: '',
        },
        onSubmit: values => {
            let payload = {
                password: values.password,
                password_confirmation: values.confirmation
            }
            appCtx.axios.put(`/api/users/${appCtx.userInfo.id}/password`, payload)
                .then(resp => window.location.reload())
                .catch(err => toastError(err))
        },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .min(8, 'A senha deve possui ao menos 8 caracteres.')
                .matches('[@#$%^&+=]', 'A senha deve possuir ao menos um caractere especial.')
                .matches('[0-9]', 'A senha deve possuir ao menos um número.')
                .matches('[a-z]', 'A senha deve possuir ao menos uma letra minúscula.')
                .matches('[A-Z]', 'A senha deve possuir ao menos uma letra maiúscula.')
                .required('É obrigatório informar a nova senha.'),
            confirmation: Yup.string()
                .required('É obrigatório confirmar a nova senha.')
                .when('password', {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf([Yup.ref('password')], 'As senhas devem ser iguais.')
                })
        })
    });

    return (
        <div
            className='w-full h-screen flex flex-col justify-center items-center bg-green-300'
            style={{
                backgroundImage: `url(${main_bg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
                <form
                    className='bg-white grid gap-2 px-6 py-4 rounded-md'
                    onSubmit={formik.handleSubmit}
                >
                    <h3 className='text-gray-700 text-lg font-semibold text-center'>Sua senha expirou!</h3>
                    <span className='text-gray-700'>Informe uma nova senha para continuar seu acesso</span>
                    <TextField
                        id='pw'
                        name='password'
                        type={showPw ? 'text' : 'password'}
                        label='Nova senha'
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        InputProps={{
                            endAdornment: <IconButton
                                onClick={() => setShowPw(!showPw)}
                                title='Mostrar senha'
                                edge='end'
                            >
                                {showPw ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        }}
                        error={!!formik.errors.password}
                        helperText={formik.errors.password ?? ''}
                    />
                    <TextField
                        id='conf'
                        name='confirmation'
                        type={showConf ? 'text' : 'password'}
                        label='Confirme a nova senha'
                        value={formik.values.confirmation}
                        onChange={formik.handleChange}
                        InputProps={{
                            endAdornment: <IconButton
                                onClick={() => setShowConf(!showConf)}
                                title='Mostrar confirmação da senha'
                                edge='end'
                            >
                                {showConf ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        }}
                        error={!!formik.errors.confirmation}
                        helperText={formik.errors.confirmation ?? ''}
                    />
                    <Button
                        variant='contained'
                        color='main'
                        type='submit'
                    >Salvar</Button>
                </form>
        </div>
    )
}
