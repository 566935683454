import React from 'react'
import { Outlet } from 'react-router-dom';
import '../../../styles/App.css';
import { OrdersContextProvider } from './components/Context';

export default function Orders() {

  return (
    <OrdersContextProvider>
      <Outlet />
    </OrdersContextProvider>
  );
}
