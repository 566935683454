import React from 'react'
import { useMatch } from 'react-router-dom'
import Page from '../../layout/Page'
import ManagementTabs from '../../shared/components/ManagementTabs'
import { CorporationsContextProvider } from './components/Context'

export default function Corporations() {

  const create = useMatch('configuracoes/empresas/novo')
  const settings = useMatch('configuracoes/empresas/')

  return (
    <CorporationsContextProvider>
      <Page title='Empresas'>
        <ManagementTabs path={'/configuracoes/empresas/'} create={create} settings={settings} />
      </Page>
    </CorporationsContextProvider>
  )
}
