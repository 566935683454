import { FileDownload, LocalShipping, Plagiarism, ReceiptLong, Thermostat } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React, { useState } from 'react'
import ModalFrame from '../../../shared/components/ModalFrame'
import OrderTracking from './OrderTracking'
import TemperatureTracking from './TemperatureTracking'

export default function OrderActions({ row }) {

    const [trackingOpen, setTrackingOpen] = useState(false)
    const [tempOpen, setTempOpen] = useState(false)
    
    // handlers
    const _handleDetails = () => window.open(`https://ministerio.ibllogistica.com.br/tracking/${window.btoa(row.order_hash)}`, '_blank')
    const _handleTemperature = () => setTempOpen(true)
    const _handleTracking = () => setTrackingOpen(true)
    const _handleDownload = () => window.location.href = `https://ministerio.ibllogistica.com.br/api/orders/${row.id}/download`
    const _handleReceipt = () => window.location.href = `https://web.ibllogistica.com.br/api/v1/comprovantes/${window.btoa(row.order)}`

    return (
        <div className='w-full flex items-center justify-center'>
            <Tooltip title='Detalhes do pedido'>
                <button
                    className='cursor-pointer hover:text-gray-600'
                    onClick={_handleDetails}
                ><Plagiarism />
                </button>
            </Tooltip>

            <Tooltip title='Temperatura'>
                <span>
                    <button
                        className={`cursor-pointer ${(row.sent_to_transport === '1') ? 'hover:text-gray-600' : 'text-gray-500 pointer-events-none'} `}
                        onClick={_handleTemperature}
                        disabled={!(row.sent_to_transport === '1')}
                    ><Thermostat />
                    </button>
                </span>
            </Tooltip>

            <Tooltip title='Rastreamento'>
                <span>
                    <button
                        className={`cursor-pointer ${(row.sent_to_transport === '1') ? 'hover:text-gray-600' : 'text-gray-500 pointer-events-none'} `}
                        onClick={_handleTracking}
                        disabled={!(row.sent_to_transport === '1')}
                    ><LocalShipping />
                    </button>
                </span>
            </Tooltip>

            <Tooltip title='Download do arquivo'>
                <button
                    className='cursor-pointer hover:text-gray-600'
                    onClick={_handleDownload}
                ><FileDownload />
                </button>
            </Tooltip>

            <Tooltip title='Download dos comprovantes'>
                <button
                    className='cursor-pointer hover:text-gray-600'
                    onClick={_handleReceipt}
                ><ReceiptLong />
                </button>
            </Tooltip>

            <ModalFrame
                open={trackingOpen}
                onClose={() => setTrackingOpen(false)}
            >
                <OrderTracking rowId={row.id} />
            </ModalFrame>

            <ModalFrame
                open={tempOpen}
                onClose={() => setTempOpen(false)}
            >
                <TemperatureTracking rowId={row.id} />
            </ModalFrame>
        </div >
    )
}
