import React from 'react'
import { useMatch } from 'react-router-dom'
import Page from '../../layout/Page'
import ManagementTabs from '../../shared/components/ManagementTabs'
import { ClassificationsContextProvider } from './components/Context'

export default function Classifications() {

  const create = useMatch('classificacoes/novo')
  const settings = useMatch('classificacoes/')

  return (
    <ClassificationsContextProvider>
      <Page title='Classificações'>
        <ManagementTabs path={'/classificacoes'} create={create} settings={settings} />
      </Page>
    </ClassificationsContextProvider>
  )
}
