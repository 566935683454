import React, { createContext, useContext, useState } from 'react'
import { AppContext } from '../../../context/AppContextProvider'
import { toastError } from '../../../shared/scripts/utilities'

export const UsersContext = createContext(null)
export function UsersContextProvider({ children }) {
    const appCtx = useContext(AppContext)
    const [users, setUsers] = useState([])
    const [pending, setPending] = useState(false)

    const _fetchUsers = async () => {
        try {
            setPending(true)
            const resp = await appCtx.axios.get('/api/users?per_page=200')
            setUsers(resp.data.data)
            setPending(false)
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <UsersContext.Provider
            value={{
                users,
                setUsers,
                pending,
                axios: appCtx.axios,
                update: _fetchUsers,
            }}
        >
            {children}
        </UsersContext.Provider>
    )
}
