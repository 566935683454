import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../context/AppContextProvider'

export default function Nav({ stack }) {
    const appCtx = useContext(AppContext)
    return (
        <div className='nav w-full px-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 z-50'>
            {
                stack.map(route => {

                    if (!route.enabled) return false
                    if (!appCtx.hasAbility('admin') && route.path === 'configuracoes') return false;

                    return (<Link
                        to={route.path}
                        className='link flex-1 ms-blue rounded-md shadow-md p-4 h-32 flex flex-col items-center text-white'
                        key={route.name}
                        style={{
                            cursor: 'pointer'
                        }}
                        replace
                    >
                        <span className='w-full text-center text-xl capitalize mb-4'>
                            {
                                route.icon
                                    ? <span className='mb-2'>{route.icon}</span>
                                    : null
                            }
                            <span className='ml-2'>{route.name}</span>
                        </span>

                        <span className='w-full text-center text-sm'>
                            {route.description}
                        </span>
                    </Link>)
                })

            }
        </div>

    )
}
