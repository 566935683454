import { Delete } from '@mui/icons-material'
import { CircularProgress, FormControl, Input, InputLabel } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { dataTable } from '../../../../context/DataTableConfig'
import { formatString, toastError } from '../../../../shared/scripts/utilities'
import { MailsContext } from '../Context'
import ModalFrame from '../../../../shared/components/ModalFrame'
import Confirm from '../../../../shared/components/Confirm'
import EditMail from '../forms/EditMail'

export default function MailsTable() {
    const ctx = useContext(MailsContext)
    const [search, setSearch] = useState('')
    const [mail, setMail] = useState(null)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)

    const columns = [
        {
            name: 'ID',
            cell: row => row.id,
            selector: row => JSON.stringify(row.id),
            sortable: true,
            center: true,
        },
        {
            name: 'Email',
            cell: row => row.email,
            selector: row => JSON.stringify(row.email),
            sortable: true,
            center: true,
        },
        {
            name: 'Ativo',
            cell: row => row.active === '1' ? 'Sim' : 'Não',
            selector: row => JSON.stringify(row.active),
            sortable: true,
            center: true,
        },
        {
            name: 'Ações',
            cell: row => <div className='w-full flex justify-center text-gray-800'>
                {/* <span
                    title='editar'
                    className='cursor-pointer'
                    onClick={() => _openEdit(row)}>
                    <Edit />
                </span> */}
                <span
                    title='excluir'
                    className='cursor-pointer'
                    onClick={() => _confirmDelete(row)}>
                    <Delete />
                </span>
            </div>,
            center: true,
        }
    ]

    const _handleDelete = async id => {
        try {
            const resp = await ctx.axios.delete(`/api/settings/mailing/${id}`)
            toast.success(resp.data.message)
            setMail(null)
            ctx.update()
        } catch (err) {
            toastError(err)
            setMail(null)
        }
    }

    const _openEdit = row => {
        setMail(row)
        setEditOpen(true)
    }


    const _confirmDelete = row => {
        setMail(row)
        setDeleteOpen(true)
    }

    useEffect(() => {
        ctx.update()
        return () => {
            const ac = new AbortController();
            ac.abort();
        }
    }, [])

    return (
        <>
            <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                Gerenciar Emails
            </h3>
            <DataTable
                data={ctx.mails.filter(item => {
                    if (
                        formatString(item.id).indexOf(formatString(search)) !== -1 ||
                        formatString(item.email).indexOf(formatString(search)) !== -1
                    )
                        return true
                    else
                        return false
                })}
                subHeader
                subHeaderAlign={window.screen.width > 400 ? 'right' : 'left'}
                subHeaderComponent={<FormControl>
                    <InputLabel htmlFor='srch_usr'>Buscar</InputLabel>
                    <Input
                        id='srch_usr'
                        value={search}
                        onChange={e => setSearch(e.target.value)} />
                </FormControl>}
                columns={columns}
                pagination
                paginationComponentOptions={dataTable.options.paginationConponentOptions}
                customStyles={dataTable.theme.default}
                progressPending={ctx.pending}
                progressComponent={<CircularProgress />}
                noDataComponent={'Nenhum resultado'}
                sortIcon={dataTable.options.sortIcon}
                sortFunction={dataTable.options.sortFunction}
            />
            {(mail && (<ModalFrame
                open={editOpen}
                onClose={() => {
                    setMail(null)
                    setEditOpen(false)
                }}>
                <EditMail mailId={mail.id} />
            </ModalFrame>))}

            {(mail && (<Confirm
                open={deleteOpen}
                setOpen={setDeleteOpen}
                title='Excluir Email'
                message={`Confirmar exclusão do email ${mail.email}?`}
                onConfirm={() => _handleDelete(mail.id)}
            />))}

        </>
    )
}
