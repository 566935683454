import React from 'react'
import { useMatch } from 'react-router-dom'
import Page from '../../layout/Page'
import ManagementTabs from '../../shared/components/ManagementTabs'
import { AgendasContextProvider } from './components/Context'

export default function Agendas() {

  const create = useMatch('pautas/novo')
  const settings = useMatch('pautas/')

  return (
    <AgendasContextProvider>
      <Page title='Pautas'>
        <ManagementTabs path={'/pautas'} create={create} settings={settings} />
      </Page>
    </AgendasContextProvider>
  )
}
