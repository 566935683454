import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../context/AppContextProvider'
import { toastError } from '../../../shared/scripts/utilities'

export const OrdersContext = createContext(null)
export function OrdersContextProvider({ children }) {
    const appCtx = useContext(AppContext)
    const [orders, setOrders] = useState([])
    const [programs, setPrograms] = useState([])
    const [agendas, setAgendas] = useState([])
    const [companies, setCompanies] = useState([])
    const [classifications, setClassifications] = useState([])
    const [pending, setPending] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [clearSelected, setClearSelected] = useState(false)

    const modals = [
        'Aereo',
        'Terrestre'
    ]

    const statusList = [
        'Aguardando aprovação',
        'Aprovado',
        'Aguardando separação',
        'Em processo de embarque',
        'Cancelado',
        'Enviado para transportadora',
        'Expedido',
        'Entregue',
        'Disponível para embarque',
        'Em separação',
    ]

    // fetchs

    const _fetchOrders = async () => {
        try {
            _clear()
            setOrders([])
            const resp = await appCtx.axios.get('/api/orders')
            setOrders(resp.data.data)
        } catch (err) {
            _clear()
            toastError(err)
        }
    }

    const _fetchPrograms = async () => {
        try {
            const resp = await appCtx.axios.get('/api/programs')
            setPrograms(resp.data)
        } catch (err) { toastError(err) }
    }

    const _fetchAgendas = async () => {
        try {
            const resp = await appCtx.axios.get('/api/agendas')
            setAgendas(resp.data)
        } catch (err) { toastError(err) }
    }

    const _fetchCompanies = async () => {
        try {
            const resp = await appCtx.axios.get('/api/companies')
            setCompanies(resp.data)
        } catch (err) { toastError(err) }
    }

    const _fetchClassifications = async () => {
        try {
            const resp = await appCtx.axios.get('/api/classifications')
            setClassifications(resp.data)
        } catch (err) { toastError(err) }
    }

    const _fetchAll = () => {
        _fetchOrders();
        _fetchAgendas();
        _fetchClassifications();
        _fetchCompanies();
        _fetchPrograms();
    }

    // functions

    function _clearSelected() {
        return new Promise((resolve, reject) => {
            let btn = document.querySelector("[name='select-all-rows']")
            setSelectedRows([])
            setClearSelected(true)
            btn ? (btn.click(), btn.click())() : resolve(false)
        })
    }

    const _clear = () => _clearSelected().then(resp => setClearSelected(resp))


    useEffect(() => {
        _fetchAll();
        return () => {
            let ac = new AbortController()
            ac.abort()
        }
    }, [])

    return (
        <OrdersContext.Provider
            value={{
                orders,
                setOrders,
                modals,
                programs,
                setPrograms,
                agendas,
                setAgendas,
                companies,
                setCompanies,
                classifications,
                setClassifications,
                statusList,
                selectedRows,
                setSelectedRows,
                pending,
                clearSelected,
                setClearSelected,
                clear: _clear,
                axios: appCtx.axios,
                update: _fetchOrders,
            }}
        >
            {children}
        </OrdersContext.Provider>
    )
}
