import { KeyboardArrowDown } from "@mui/icons-material";
import NoData from "../shared/components/NoData";

export const dataTable = {
    theme: {
        default: {
            headCells: {
                style: {
                    backgroundColor: '#094780',
                    fontSize: '1rem',
                    color: 'white'
                },
            },
            pagination: {
                style: {
                    backgroundColor: '#e7e7e7',
                    fontSize: '.9rem',
                    color: 'black',
                }
            }
        },
    },
    options: {
        paginationConponentOptions: {
            rowsPerPageText: 'Itens por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        },
        sortFunction: (rows, selector, direction) => {
            return rows.sort((a, b) => {
                const aField = selector(a).toLowerCase();
                const bField = selector(b).toLowerCase();

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }

                return direction === 'desc' ? comparison * -1 : comparison;
            });
        },
        sortIcon: <KeyboardArrowDown />,
        noDataComponent: <NoData />
    },
}


