import { Delete, Edit, LockReset, Visibility } from '@mui/icons-material'
import { CircularProgress, FormControl, Input, InputLabel } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { dataTable } from '../../../../context/DataTableConfig'
import { formatString, toastError } from '../../../../shared/scripts/utilities'
import { UsersContext } from '../Context'
import EditUser from '../forms/EditUser'
import ModalFrame from '../../../../shared/components/ModalFrame'
import Confirm from '../../../../shared/components/Confirm'
import { AppContext } from '../../../../context/AppContextProvider'

export default function UsersTable() {
    const appCtx = useContext(AppContext)
    const ctx = useContext(UsersContext)

    const [search, setSearch] = useState('')
    const [editOpen, setEditOpen] = useState(false)
    const [user, setUser] = useState(null)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [resetOpen, setResetOpen] = useState(false)

    const columns = [
        {
            name: 'ID',
            cell: row => row.id,
            selector: row => JSON.stringify(row.id),
            sortable: true,
            center: true,
        },
        {
            name: 'Usuário',
            cell: row => row.username,
            selector: row => JSON.stringify(row.username),
            sortable: true,
            center: true,
        },
        {
            name: 'Email',
            cell: row => row.email,
            selector: row => JSON.stringify(row.email),
            sortable: true,
            center: true,
        },
        {
            name: 'Ações',
            cell: row => <div className='w-full flex justify-center text-gray-800'>
                <span
                    title='visualizar atividades'
                    className='cursor-pointer'
                    onClick={_handleVisualize}>
                    <Visibility />
                </span>
                <span
                    title='editar'
                    className='cursor-pointer'
                    onClick={() => _openEdit(row)}>
                    <Edit />
                </span>
                <span
                    title='resetar senha'
                    className='cursor-pointer'
                    onClick={() => _confirmReset(row)}>
                    <LockReset />
                </span>
                <span
                    title='excluir'
                    className='cursor-pointer'
                    onClick={() => _confirmDelete(row)}>
                    <Delete />
                </span>
            </div>,
            center: true,
        }
    ]

    const _handleDelete = async id => {
        try {
            const resp = await appCtx.axios.delete(`/api/users/${id}`)
            toast.success(resp.data.message)
            setUser(null)
            ctx.update()
        } catch (err) {
            console.log(err)
            toastError(err)
            setUser(null)
        }
    }

    const _handleReset = async id => {
        try {
            const resp = await appCtx.axios.post(`/api/users/${id}/reset`)
            toast.success(resp.data.message)
            setUser(null)
           if(appCtx.userInfo.id == id) window.location.reload()
        } catch (err) {
            toastError(err)
            setUser(null)
        }
    }

    const _handleVisualize = async id => {
        try {
            // const resp = await appCtx.axios.get(`/api/users/${id}/logs`)
            // toast.success(resp.data.message)
            alert('You do not have access to this feature. Check out for our latest DLCs available at the store!')

        } catch (err) {
            toastError(err)
        }
    }

    const _openEdit = row => {
        setUser(row)
        setEditOpen(true)
    }

    const _confirmReset = row => {
        setUser(row)
        setResetOpen(true)
    }

    const _confirmDelete = row => {
        setUser(row)
        setDeleteOpen(true)
    }

    useEffect(() => {
        ctx.update()
        return () => {
            const ac = new AbortController();
            ac.abort();
        }
    }, [])

    return (
        <>
            <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                Gerenciar Usuários
            </h3>
            <DataTable
                data={ctx.users.filter(item => {
                    if (
                        formatString(item.id).indexOf(formatString(search)) !== -1 ||
                        formatString(item.username).indexOf(formatString(search)) !== -1 ||
                        formatString(item.email).indexOf(formatString(search)) !== -1
                    )
                        return true
                    else
                        return false
                })}
                subHeader
                subHeaderAlign={window.screen.width > 400 ? 'right' : 'left'}
                subHeaderComponent={<FormControl>
                    <InputLabel htmlFor='srch_usr'>Buscar</InputLabel>
                    <Input
                        id='srch_usr'
                        value={search}
                        onChange={e => setSearch(e.target.value)} />
                </FormControl>}
                columns={columns}
                pagination
                paginationComponentOptions={dataTable.options.paginationConponentOptions}
                customStyles={dataTable.theme.default}
                progressPending={ctx.pending}
                progressComponent={<CircularProgress />}
                noDataComponent={'Nenhum resultado'}
                sortIcon={dataTable.options.sortIcon}
                sortFunction={dataTable.options.sortFunction}
            />
            {(user && (<ModalFrame
                open={editOpen}
                onClose={() => {
                    setUser(null)
                    setEditOpen(false)
                }}>
                <EditUser userId={user.id} />
            </ModalFrame>))}

            {(user && (<Confirm
                open={deleteOpen}
                setOpen={setDeleteOpen}
                title='Excluir Usuário'
                message={`Confirmar exclusão do usuário ${user.username}?`}
                onConfirm={() => _handleDelete(user.id)}
            />))}

            {(user && (<Confirm
                open={resetOpen}
                setOpen={setResetOpen}
                title='Resetar Senha'
                message={`Confirmar redefinição da senha de ${user.username}?`}
                onConfirm={() => _handleReset(user.id)}
            />))}

        </>
    )
}
