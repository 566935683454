import { Mail, People, Settings as GearIcon } from '@mui/icons-material'
import { Divider } from '@mui/material'
import React from 'react'
import Page from '../../layout/Page'
import Nav from '../../shared/components/Nav'


export default function Settings() {

  const routes = [
    {
      path: 'usuarios',
      name: 'Usuários',
      enabled: true,
      icon: <People />,
      description: 'Criar e gerenciar usuários',
    },
    {
      path: 'empresas',
      name: 'Empresas',
      enabled: true,
      icon: <GearIcon />,
      description: 'Criar e gerenciar empresas',
    },
    {
      path: 'emails',
      name: 'Emails',
      enabled: true,
      icon: <Mail />,
      description: 'Gerenciar emails que recebem alertas da aplicação',
    },
  ]

  return (
    <Page title='Configurações'>
      <div className='w-full flex-col justify-center items-center'>
        <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
          Painel de Controle
        </h3>
        <Nav stack={routes} />
      </div>
    </Page>
  )
}
