import { Button, Checkbox, Fade, FormControl, FormControlLabel, Input, InputLabel } from '@mui/material'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { toastError } from '../../../../shared/scripts/utilities'
import { MailsContext } from '../Context'

export default function CreateMail() {

    const ctx = useContext(MailsContext)

    const [form, setForm] = useState({
        email: '',
        active: false
    })

    const _handleSubmit = async () => {
        try {
            const resp = await ctx.axios.post('/api/settings/mailing', form)
            toast.success(resp.data.message)
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <>
            <h3 className='w-full text-semibold text-center text-gray-600 my-10 text-2xl'>
                Novo Email
            </h3>
            <Fade in={true}>

                <div className='w-full flex flex-col jusitfy-center items-center'>
                    <form
                        id='crt_usr_frm'
                        onSubmit={e => {
                            e.preventDefault()
                            _handleSubmit()
                        }}

                        className='w-full max-w-lg grid grid-cols-1 gap-4 py-2'
                    >
                        <FormControl>
                            <InputLabel htmlFor='email'>E-mail*</InputLabel>
                            <Input
                                id='email'
                                aria-describedby='email de usuário'
                                value={form.email}
                                onChange={e => setForm({ ...form, email: e.target.value })}
                            />
                        </FormControl>

                        <FormControlLabel
                            label="Ativo"
                            control={
                                <Checkbox
                                    checked={form.active}
                                    onChange={e => setForm({ ...form, active: e.target.checked })}
                                />
                            }
                        />

                        <div className='w-full flex justify-between items-center'>
                            <Button
                                type='reset'
                                onClick={() => setForm({
                                    email: '',
                                    active: false
                                })}
                            >Limpar</Button>

                            <Button
                                type='submit'
                                variant='contained'
                            >Criar</Button>
                        </div>

                    </form>
                </div>
            </Fade>
        </>
    )
}
