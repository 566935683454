import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';
import React, { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { routes } from '../config/routes';
import { toastError } from '../shared/scripts/utilities';

export const AppContext = createContext(null);
export default function AppContextProvider({ children }) {
    const [userInfo, setUserInfo] = useState({});
    const [loadingApp, setLoadingApp] = useState(false);
    const [history, setHistory] = useState([])
    const [allowed_routes, setRoutes] = useState([])
    const [loggedIn, setLoggedIn] = useState(false)
    const [autoCookie, setAutoCookie] = useState(false)

    const navigate = useNavigate()

    const appAxios = axios.create({
        baseURL: 'https://ministerio.ibllogistica.com.br',
        withCredentials: true,
    })

    appAxios.interceptors.request.use(req => {
        let cookie = Boolean(Cookies.get('radar_vacina_session_validation'));
        if (!cookie && req.url.indexOf('/auth/sign') === -1) {
            _logout();
            return Promise.reject();
        }

        return req;

    }, err => {
        return Promise.reject(err);
    })

    appAxios.interceptors.response.use(resp => resp, err => {
        if (err.response.status === 401 && err.request.responseURL.indexOf('/signout') === -1 && loggedIn) {
            _logout()
            return Promise.reject();
        } else if (err.response.status === 401) {
            navigate('login')
        }

        return Promise.reject(err);
    });

    // handlers
    const _changeCookie = bool => {
        setAutoCookie(true);
        setLoggedIn(bool);
    }

    // functions
    const _logout = async () => {
        try {
            await axios.post('/api/auth/signout');
            Cookies.remove('radar_vacina_session_validation');
            _changeCookie(false);
            navigate('login');
        } catch (err) {
            if (err.response.status === 401) {
                Cookies.remove('radar_vacina_session_validation');
                _changeCookie(false);
                navigate('login');
            } else {
                toastError(err);
            }
        }
    }

    const _login = async (credentials, clear) => {
        try {
            await axios.get('/sanctum/csrf-cookie')
            await appAxios.post('/api/auth/signin', {
                login: credentials.login,
                password: credentials.password
            })
            Cookies.set('radar_vacina_session_validation', true, { expires: 86400, sameSite: 'lax' });
            navigate('/');
            _fetchUser();
        } catch (err) {
            toastError(err)
            clear()
        }
    }

    const _hasAbility = ability => {
        if (_.isEmpty(userInfo)) return false;
        return userInfo.permissions.abilities.indexOf(ability) !== -1 ||
            userInfo.permissions.abilities.indexOf('admin') !== -1;
    }

    // fetchs
    const _fetchUser = async () => {
        try {
            let cookie = Boolean(Cookies.get('radar_vacina_session_validation'));
            _changeCookie(cookie);
            if (window.location.href.indexOf('/login') !== -1) return;
            const resp = await appAxios.get('/api/user/info')
            setUserInfo(resp.data)
        } catch (err) {
            toastError(err)
        }

    }

    useEffect(() => {
        _fetchUser();
        return () => {
            let ac = new AbortController()
            ac.abort()
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(userInfo)) {
            setLoadingApp(false)
            let userRoutes = userInfo.permissions.abilities.indexOf('admin') !== -1
                ? routes
                : routes.filter(route => userInfo.permissions.abilities.indexOf(`${route.path}:`) !== -1 ||
                    route.name === 'home' ||
                    route.name === '404' ||
                    route.name === 'login'
                )
            setRoutes(userRoutes)
        }
    }, [userInfo]);

    return (
        <AppContext.Provider value={{
            userInfo,
            loadingApp,
            history,
            allowed_routes,
            setHistory,
            loggedIn,
            autoCookie,
            fetchUser: _fetchUser,
            logout: _logout,
            login: _login,
            hasAbility: _hasAbility,
            axios: appAxios,
        }}>
            {children}
        </AppContext.Provider>
    )
}
